html {
	body {
		#app.session-layout {
			&.signup-success {
				grid-template-columns: auto $gutter * 20 auto;
			}

			&.wizard {
				grid-template-rows: $gutter * 6 1fr $gutter * 2;
				grid-template-columns: auto $gutter * 25 auto;

				article#session-card {
					.card-block {
						section#wizard {
							padding: 0;
							margin: 0;

							article#header-card {
								border: none;
								border-bottom: 1px solid $hairline-grey;
								border-radius: 0;

								.card-block {
									padding: 0;
									display: flex;
									flex-direction: column;
									justify-content: center;

									.page-header-block {
										padding: 0;
									}
								}
							}

							.row {
								.col {
									&:only-child {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}

			background-color: $blue;
			background-position: top left;
			background-repeat: repeat;

			height: 100vh;
			width: 100%;
			display: grid;
			grid-template-columns: auto $gutter * 10 auto;
			grid-template-rows: $gutter * 10 1fr $gutter * 2;
			grid-column-gap: $one-unit * 2;
			grid-row-gap: $one-unit * 2;

			> header {
				align-self: center;
				justify-self: center;
				grid-column: 2;
				grid-row: 1;
			}

			> article {
				align-self: start;
				justify-self: stretch;
				grid-column: 2;
				grid-row: 2;
			}

			> footer {
				align-self: end;
				justify-self: center;
				grid-column: 3 span;
				grid-row: 3;
			}

			a {
				&.btn-success {
					font-weight: 200;
					font-size: $font-size * 1.2;
					padding: 10px 0;
					color: $white;
					border-color: darken($green, 30);
					background-color: darken($green, 30);

					&:hover {
						border-radius: $border-radius;
						background-color: $green;
						color: mix($white, $green);
					}
				}
			}

			header {
				a {
					text-align: center;
					display: block;

					&:hover {
						background-color: transparent;
					}

					svg#logo {
						@include animate-state;
						z-index: 999;
						margin: 0;
						max-width: 150px;
						max-height: 150px;

						g#border {
							path.ball,
							path.horizontal-arc {
								fill: $white;
							}
						}

						g#letters {
							path#letter-c,
							path#letter-t {
								fill: $white;
							}
						}
					}
				}
			}

			article#session-card {
				@include reset;
				@include depth;
				border-radius: $border-radius;
				background-color: $white;
				z-index: 2;

				.card-block {
					padding: $gutter;

					.card-header {
						padding: 0;
						margin: 0;
						background: none;
						border: none;
					}

					header {
						text-align: left;
						margin: 0;
					}

					h1 {
						font-size: $one-unit * 3;
						line-height: 1;
						font-weight: 400;
					}

					#oauth {
						display: flex;
						margin: $one-unit 0 $one-unit * 2;

						.btn {
							flex: 1;
							box-shadow: 0;

							&::before {
								color: $white;
								margin-right: $one-unit;
								display: inline-block;
								font: normal normal normal 14px/1 FontAwesome;
							}

							border-radius: 0;
							box-shadow: none;

							&:first-child {
								border-radius: $one-unit 0 0 $one-unit;
							}

							&:last-child {
								border-radius: 0 $one-unit $one-unit 0;
							}
						}
					}

					form {
						display: flex;
						flex-direction: column;

						.form-group {
							margin-bottom: $one-unit * 2;

							.form-control-feedback {
								opacity: 0.8;
								font-size: round($font-size * 0.8);
							}

							.form-control {
								&:hover {
									@include animate-state;
									background-color: transparent;
									border-color: mix($white, $blue);
								}

								&:focus {
									@include animate-state;
									background-color: transparent;
									border-color: darken($blue, 10);
								}

								&:-webkit-autofill,
								&:-webkit-autofill:focus,
								&:-webkit-autofill:hover {
									border-color: $accent-color2;
									-webkit-text-fill-color: $black;
									-webkit-box-shadow: 0 0 0 1000px $white inset;
									box-shadow: 0 0 0 1000px $white inset;
									transition: background-color 5000s ease-in-out 0s;
								}
							}

							&.has-danger {
								label {
									color: $red !important;
								}

								.form-control {
									border-color: $red;
								}
							}
						}

						button {
							@include depth;
							flex: 1;
							min-height: $gutter * 1.5;

							&:hover {
								background-color: darken($blue, 10);
							}
						}
					}

					.offline {
						text-align: center;
					}

					.loader {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;

						svg {
							margin: $one-unit * 2;
							flex: 1;
						}
					}
				}
			}

			.loader {
				background-color: transparent;
			}

			footer {
				color: $white;

				a {
					color: $white;
				}
			}

			#clouds {
				width: 100%;
				height: 300px;
				position: fixed;
				bottom: 0;

				.cloud {
					position: absolute;
					z-index: 100;

					path {
						opacity: 1;
						transform: scale(1);
						fill: rgba(255, 255, 255, 0.1);
					}
				}

				.cloud1 {
					top: 0px;
					animation: move 200s linear infinite;
					transform: scaleX(-1);
				}

				.cloud2 {
					top: 30px;
					animation: move 350s linear 10s infinite backwards;
				}
			}

			@keyframes move {
				from {
					-webkit-transform: translateX(-400px);
				}

				to {
					-webkit-transform: translateX(1350px);
				}
			}

			@-webkit-keyframes move {
				from {
					-webkit-transform: translateX(-400px);
				}

				to {
					-webkit-transform: translateX(1350px);
				}
			}
		}
	}
}

@media only screen and (max-height: 415px) {
	#app.session-layout {
		.container-fluid {
			header {
				@include animate-state;
				height: 50px;

				svg {
					@include animate-state;
					position: absolute;
					top: -20px;
					right: 0px;
					transform: scale(0.4);
					opacity: 1;
				}
			}

			article {
				header {
					height: auto;
				}

				form {
					.form-group {
						.form-control {
							font-size: $font-size * 2;
						}
					}

					button {
						font-size: round($font-size * 1.5);
					}
				}
			}
		}
	}
}

@media only screen and (max-height: 500px) {
	#app.session-layout {
		.container-fluid {
			header {
				@include animate-state;
				margin: 0;

				svg {
					@include animate-state;
					transform: scale(0.4);
				}
			}

			footer {
				@include animate-state;
				opacity: 0.2;
			}
		}
	}
}
