#app {
	.tasks.show {
		&.task-closed {
			.btn-edit {
				display: none;
			}
			.list-group-component-conditions {
				.list-group-item.condition {
					.condition-block {
						.form-group-date,
						.btn-undo,
						.upload {
							display: none !important;
						}
						.condition-actions {
							button {
								display: none;
							}
						}
					}
				}
			}
		}
		.list-group-component-documents {
			width: 70vw;
		}
	}
}
