@import "pdf";
@import "image";
.application-layout {
	section.documents {
		.preview.card {
			&.audio {
				.video-react {
					padding: 0 !important;
					height: 32px;

					.video-react-big-play-button {
						display: none;
					}

					.video-react-control-bar {
						display: flex;
					}
				}
			}

			.video-react {
				font-family: $font-family-body;
				background-color: $black;
			}

			.card-block {
				display: flex;

				.pdf-viewer {
					flex: 1;
				}

				.prism-code {
					width: 100%;
					padding: $one-unit * 2;
					margin: $one-unit * 2 0;
					background-color: black !important;
					border-radius: 3px;
					color: $white !important;

					.token-line {
						color: $white !important;
						font-size: $font-size !important;

						.tag {
							@include reset;
							min-width: 0;
							font-size: $font-size;
						}
					}
				}

				.plain-text {
					justify-self: center;
					margin: $one-unit * 2;
					color: $black;
					font-size: $font-size;
					padding: $one-unit * 2;
					width: 20 * $gutter;
					border: 1px solid $hairline-grey;
				}

				.preview-unavailable {
					padding: $one-unit * 2;
				}

				.document-preview {
					@include animate-state;
					background-color: $black;
					text-align: center;
					padding: 0;
				}
			}
		}

		.document-status {
			position: absolute;
			top: $gutter * -2;
			left: $one-unit;
			font-size: round($font-size * 1.5);
		}
	}

	&.document-expired {
		section.documents {
			.cover {
				@include background-red;
			}

			.document-status {
				color: $white;
			}
		}
	}

	&.document-deleted {
		section.documents {
			.cover {
				@include background-orange;
			}

			.document-status {
				color: $white;
			}

			.card.preview {
				.card-block {
					padding: $one-unit * 2;

					blockquote {
						padding: $one-unit * 2;
						border: 1px solid $accent-color2;
						font-family: serif;
						font-size: $font-size;
						font-weight: 100;

						&::before {
							content: "“";
						}

						&::after {
							content: "”";
						}
					}
				}
			}
		}
	}
}
