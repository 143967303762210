nav.workflow-tabs {
  @include reset;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid $hairline-grey;
  position: relative;
  margin-top: $one-unit * 2;
  margin-bottom: $one-unit * 2;
  left: $gutter * -0.5;
  padding-left: $gutter * 0.5;
  width: calc(100% + 30px);
  padding-right: $gutter;
  a.workflow-tab {
    vertical-align: baseline;
    position: relative;
    display: inline-block;
    padding: $one-unit;
    flex: 1;
    border-top: 1px solid $hairline-grey;
    border-left: 1px solid $hairline-grey;
    border-right: 1px solid $hairline-grey;
    margin-right: $one-unit/4;
    border-bottom: 0;
    border-radius: $one-unit/4 $one-unit/4 0 0;
    font-weight: 200;
    text-decoration: none;
    &:hover {
      background-color: $blue;
      color: $white;
    }
    &.workflow-tab-active {
      &:hover {
        cursor: default;
      }
      opacity: 1;
      background-color: $white;
      color: $black;
      top: 1px;
    }
  }
}
