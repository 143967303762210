.condition {
	.status {
		color: $grey;
	}
}

.condition-complete {
	.status {
		color: $green;
	}
}
.condition-closed {
	.status {
		color: $green;
	}
}

.condition-dismissed {
	.status {
		color: $grey;
	}
}
