#parties-card {
  .card-block {
    .list-group {
      .list-group-section {
        .list-group-component {
          .list-group-item {
            .stamp {
              order: 1;
            }

            .list-group-item-content {
              flex: 1;
              order: 2;
            }

            .list-group-item-datapoints {
              order: 3;

              >* {
                margin-left: $one-unit;
              }

              .muted.phone-number {
                display: none;
              }
            }

            .flyout {
              order: 4;
            }
          }
        }
      }
    }
  }
}