#tenancy-page {
  article {
    header {
      dt {
        margin-right: 5px;
        font-size: round($font-size * 0.9);
        clear: left;
        display: block;
        float: left;
      }

      dd {
        display: block;
        float: left;
        margin-right: 5px;
        font-size: round($font-size * 0.9);
      }
    }
  }

  .document-preview {
    margin-bottom: $one-unit * 2;
  }
}
