#view-mode {
  label {
    margin-right: 3px;
    font-size: 0.7rem;
    color: hsla(205, 9%, 67%, 0.50);
  }

  a {
    margin-right: 5px;
    font-size: 0.7rem;
    text-decoration: none;
    vertical-align: middle;
    opacity: 0.3;
    transition: opacity 300ms ease-out;

    &:hover {
      opacity: 0.8;
      transition: opacity 300ms ease-out;
    }

    &.active {
      opacity: 1;
      transition: opacity 300ms ease-out;
    }
  }
}

@media only screen and (max-width: 425px) {
  .table-view header #view-mode {
    position: static !important;
    margin: 10px 0 5px 0;
    display: flex;
    flex-direction: row;

    a {
      font-size: $font-size * 0.9;
      flex: 1;
      text-align: center;
    }
  }
}
