.project-deadline {
	.status {
		font-size: $font-size;
	}
}
.deadline-behind {
	.status {
		color: $red;
	}
}
.deadline-at_risk {
	.status {
		color: $orange;
	}
}
.deadline-ahead {
	.status {
		color: $green;
	}
}
.deadline-closed {
	.status {
		color: $green;
	}
}
