/*GENERIC!*/
@mixin mobile-layout {
	header#main-header {
		max-width: 100%;
		display: flex;
		flex-direction: row;
		flex-basis: 100%;
		flex-wrap: wrap;
		.main-header-stamp {
			flex-basis: 10%;
			margin: 0;
		}
		.main-header-titles {
			flex-basis: 60%;
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
		}
		.main-header-toolbar {
			flex-basis: 20%;
			margin: 0;
			button[data-ref="edit"],
			button[data-ref="trash"] {
				display: none;
			}
		}
	}
	section.documents.show {
		.nav-item[data-ref="comments"],
		.nav-item[data-ref="parties"],
		.nav-item[data-ref="documents"],
		.nav-item[data-ref="tenant"],
		.nav-item[data-ref="tenancy"],
		.nav-item[data-ref="ledgers"],
		.nav-item[data-ref="lease"],
		.nav-item[data-ref="dashboard"],
		.nav-inspections,
		i {
			display: none;
		}

		a.active {
			background-color: $blue !important;
			color: $white !important;
		}
		.progress {
			display: none;
		}
		#RESOURCE_FEEDS-card {
			.activity-item a.activity-item-link .activity-item-avatar {
				display: none;
			}
		}
	}
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
	@include mobile-layout;
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
	@include mobile-layout;
}

/* ----------- iPhone 6 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	@include mobile-layout;
}

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
	@include mobile-layout;
}
/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
	@include mobile-layout;
}
