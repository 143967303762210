@mixin bounce-animation($deltaX: 10px) {
  @-webkit-keyframes bounce {
    from,
    to {
      transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
    }

    50% {
      transform: translateY($deltaX);
      -webkit-animation-timing-function: ease-in;
    }
  }
  @keyframes bounce {
    from,
    to {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }

    50% {
      transform: translateY($deltaX);
      animation-timing-function: ease-in;
    }
  }
  animation-name: bounce;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 4.8s;
}
@mixin rotate() {
  animation-name: rotate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: reverse;
  animation-duration: 3s;
  @keyframes rotate {
    to {
      transform: rotate(0deg) scale(1);
    }

    50% {
      transform: rotate(180deg) scale(0.9);
    }

    from {
      transform: rotate(359deg) scale(1);
    }
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
