.workspace {
	margin-top: $one-unit * 2;
	margin: $one-unit * 2;
	.tabs {
		.nav {
			.nav-item {
				-ms-flex: 1 1 auto;
				flex: 1 1 auto;
				text-align: center;
			}
		}
	}

	.card {
		border: none;
	}
}
