.application-layout {
	#leases-collection {
		.gantt-chart {
			width: 97%;
			margin: 0 $one-unit;
		}
		article.card {
			border-radius: 0 0 $border-radius $border-radius;
			.card-block {
				padding-top: $one-unit/4;
			}
		}
	}
}
