// a[title]:hover:after {
//     content: attr(title);
//     position: absolute;
//   }

.feature-flag {
	i.fa {
		color: $white;
		margin: 0;
	}
	-webkit-font-smoothing: antialiased;
	background-color: $red;
	color: $white;
	font-weight: 400;
	border-radius: $gutter;
	box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
	display: block;

	padding: 4px 10px;
	position: absolute;

	text-align: center;
	z-index: 1;
	font-size: $font-size * 0.9;
}
.application-layout {
	article.card {
		header {
			div.feature-flag {
				position: relative;
				top: -2 * $one-unit;
				line-height: $font-size * 0.9;
				height: $font-size * 1.4;
			}
		}
	}
}
.application-layout {
	#masthead {
		div.feature-flag {
			position: absolute;
			top: $one-unit;
			right: -$one-unit;
		}
	}
}
