$width: $one-unit * 100;

.notification-kind {
	border-bottom: 1px dotted $accent-color2;
	padding-bottom: $one-unit;
	margin-bottom: $one-unit;
	&.no-notifications {
		opacity: 0.5;
	}
	.notification-kind-header {
		display: flex;
		align-items: center;
		.notification-kind-header-active-channels {
			margin-left: $one-unit;
			font-size: $font-size * 0.9;
			color: $mutedBlack;
		}
		.notification-kind-header-icon {
			margin-right: $one-unit;
			i {
				font-size: $font-size * 1.2;
			}
		}
		.notification-kind-header-title {
			line-height: 2;
			font-size: $font-size * 1.2;
			flex: 1;
		}
		button {
			background-color: transparent;
			border: none;
			cursor: pointer;
		}
	}
	&.channels-show {
		.notification-kind-header {
			.notification-kind-header-active-channels {
				display: none;
			}
			button {
				transform: rotate(-90deg);
			}
		}
		.channels {
			display: block;
		}
		opacity: 1;
	}
	&.channels-hidden {
		.channels {
			display: none;
		}
	}
	.channels {
		.channel {
			margin-bottom: $one-unit;
			display: flex;
			align-items: center;
			// width: $width;
			// padding: $one-unit;
			padding-bottom: $one-unit;
			border-bottom: 1px solid $hairline-grey;
			&:last-of-type {
				padding-bottom: 0;
				border-bottom: none;
			}
			opacity: 0.5;
			&.channel-active {
				opacity: 1;
			}
			.channel-icon {
				order: 2;
				margin-right: $one-unit;
				i {
					font-size: $font-size * 1.5;
				}
			}
			.channel-details {
				order: 3;
				.channel-name {
					display: inline-block;
				}
				.channel-identity {
					margin-left: $one-unit;
					color: $mutedBlack;
					display: inline-block;
				}
				flex: 1;
			}

			.channel-toggle {
				order: 1;
				button {
					background-color: transparent;
					border: none;
					cursor: pointer;
				}
			}
		}
	}
}
