@import "~react-dates/css/variables";
@import "~react-dates/css/styles"; // $react-dates-width-input: 130px !default;
// $react-dates-width-arrow: 24px !default;
// $react-dates-width-tooltip-arrow: 20px !default;
// $react-dates-spacing-vertical-picker: 72px !default;
$react-dates-color-primary: $orange !default; // $react-dates-color-primary-dark: #00514a !default;
// $react-date-color-primary-dark-1: #008489 !default;
// $react-dates-color-primary-shade-1: #33dacd !default;
// $react-dates-color-primary-shade-2: #66e2da !default;
// $react-dates-color-primary-shade-3: #80e8e0 !default;
// $react-dates-color-primary-shade-4: #b2f1ec !default;
// $react-dates-color-secondary: #007a87 !default;
$react-dates-color-white: $white !default; // $react-dates-color-gray: #565a5c !default;
// $react-dates-color-gray-dark: darken($react-dates-color-gray, 10.5%) !default;
// $react-dates-color-gray-light: lighten($react-dates-color-gray, 17.8%) !default; // #82888a
// $react-dates-color-gray-lighter: lighten($react-dates-color-gray, 45%) !default; // #cacccd
// $react-dates-color-gray-lightest: lighten($react-dates-color-gray, 60%) !default;
// $react-dates-color-highlighted: #ffe8bc !default;
$react-dates-color-border: $accent-color2 !default; // $react-dates-color-border-light: #dce0e0 !default;
// $react-dates-color-border-medium: #c4c4c4 !default;
// $react-dates-color-placeholder-text: #757575 !default;
$react-dates-color-text: $dark-blue !default; // $react-dates-color-text-focus: #007a87 !default;
// $react-dates-color-focus: #99ede6 !default;
$SingleDatePicker-top: 40px;

.form-group {
  .SingleDatePicker {
    .SingleDatePicker__picker {
      z-index: 20;
      top: $SingleDatePicker-top !important;
    }
    //
    .DateInput {
      &::before {
        z-index: 21;
        top: $SingleDatePicker-top - 11;
      }

      &::after {
        z-index: 22;
        top: $SingleDatePicker-top - 10px;
      }
    }
  }
}

.SingleDatePicker {
  .CalendarMonth--horizontal {
    margin-top: 0;

    .CalendarDay--selected {
      background-color: $blue;
      border-color: $blue;
    }
  }

  .SingleDatePickerInput {
    border: none;
    border-bottom: 1px solid $accent-color2;

    .DateInput {
      // z-index: 99;

      .DateInput__display-text {
        padding: 4px 0;
      }
      padding: 5px 0;
    }
  }
}
