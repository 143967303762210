html {
	body {
		#app {
			#wizard {
				nav {
					background-color: $white;
				}
			}
		}
	}
}
