div.tenancy-shape {
  position: relative;

  .tenancy-details {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    div {
      text-align: center;
      line-height: 1;
      &.tenancy-name {
        font-weight: 800;
        font-size: round($font-size * 1.1);
      }
      &.tenancy-division {
        opacity: 0.8;
      }
    }
  }
  svg {
    outline: 1px solid $hairline-grey;
    width: 480px;
    height: 320px;

      polygon.tenancy-body {
        fill: $hairline-grey;
        // stroke-dasharray: 3000;
        // stroke-dashoffset: 3000;
        // animation: dash 3s ease-out forwards;
        // transition: fill 300ms ease-out, stroke 300ms ease-out;
      }
      // @keyframes dash {
      //   to {
      //     stroke-dashoffset: 0;
      //   }
      // }

      path {
        transform: scale(1);
        opacity: 1;
        fill: $white;
        stroke: $accent-color2;
        &.tenancy-shadow {
          opacity: 0.5;
        }
      }

      circle.poi {
        fill: $orange;
        stroke: none;
      }

      text.tenancy-name {
        fill: $black;
        font-size: 8px;
      }

      text.tenancy-division {
        fill: $grey;
        font-size: 5px;
      }

      svg.inactive .tenancy {
        opacity: 0.1;
      }
  }
}
