.activity-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	.btn {
		position: absolute;
		top: $one-unit;
		right: $one-unit;
		background-color: transparentize($color: $blue, $amount: 0.5);
	}

	&.Announce {
		background-color: $purple;
		a.activity-item-link {
			border-color: $purple;
			&:hover {
				background-color: darken($purple, 20);
				.activity-item-avatar {
					.avatar {
						div {
							color: $white;
							background-color: $purple !important;
							border-radius: 100% !important;
						}
					}
				}
			}
			.activity-item-avatar {
				.avatar {
					div {
						background-color: lighten($purple, 20) !important;
						border-radius: 100%;
					}
				}
			}
			.activity-item-container {
				.activity-item-announcement-name {
					border: 1px solid transparentize($color: $white, $amount: 0.5);
					// border-radius: 50px;
					padding: 0 $one-unit;
					position: relative;
					top: 1px;
					margin-right: $one-unit;
				}
				.activity-item-body,
				.activity-item-date {
					color: $white;
				}
			}
		}
		.btn {
			background-color: $white;
			color: #ab47bc;
		}
		&.activity-unread {
			background-color: #ab47bc;
		}
	}

	&.activity-unread {
		background-color: transparentize($color: $blue, $amount: 0.9);
		.btn {
			background-color: $white;
			color: $blue;
		}
		a.activity-item-link {
			border-bottom: 1px solid transparentize($color: $blue, $amount: 0.9);
		}
	}

	a.activity-item-link {
		text-decoration: none;
		border-bottom: 1px solid $hairline-grey;
		padding: $one-unit * 2 $one-unit;
		width: 100%;
		margin: 0;
		display: flex;
		.activity-item-avatar {
			min-width: $gutter;
			margin-right: $one-unit;
			flex-basis: $gutter;
		}
		.activity-item-container {
			flex: 1;
			.activity-item-body {
				font-size: $font-size;
				margin-bottom: $one-unit/2;
				text-transform: lowercase;
				.activity-item-actor,
				.activity-item-object,
				.activity-item-subject,
				.activity-item-context {
					text-transform: capitalize;
				}

				span,
				a {
					font-weight: 600;
				}
				blockquote {
					padding: $one-unit;
					border-left: 3px solid $mutedBlack;
					min-height: $one-unit * 3;
					margin: $one-unit/2 0;
					background-color: darken($white, 3%);
					p {
						margin: 0;
					}
				}
			}
			.activity-item-icon {
				display: inline-block;
			}
			.activity-item-date {
				display: block;
				font-size: $font-size * 0.8;
			}
		}

		&:hover {
			border-radius: $border-radius;

			.activity-item-avatar {
				.avatar {
					div {
						background-color: $white !important;
						color: $blue !important;
						border-radius: 100%;
					}
				}
			}
			.activity-item-container {
				.activity-item-body {
					span,
					a {
						color: $white;
						font-weight: 600;
					}
					color: $white;
				}
			}
		}
	}
}

aside {
	#activities {
		.activity-item {
			padding: $one-unit 0;
		}

		height: 300px;
		overflow-y: auto;
	}
}

#notifications-card {
	.activity-item {
		&.activity-unread {
		}
	}
}
