@import "./show/dependencies";
@import "./show/tasks";
@import "./show/phases";
@import "./show/parties";
@import "./show/ledgers";

section.template {
  .card {
    position: elative;

    .btn.add-task {
      position: absolute;
      top: 0;
      right: 0;
    }

    header {
      position: relative;
    }

    &.close {
      @include animate-state;
      height: 60px;
      overflow: hidden;

      p {
        display: none;
      }

      .attributes {
        display: none;
      }

      .btn {
        display: none;
      }
    }

    p.lead {
      width: 70%;
      color: $black;
      font-weight: 100;
    }

    .btn.edit-task {
      position: absolute;
      top: 0;
      right: 0;
    }

    fieldset {
      border-color: $red;
      border-radius: $gutter / 4;
      border: 1px solid $accent-color2;
      padding: $one-unit * 2;
      margin: $gutter 0 0;

      legend {
        margin: 0;
      }
    }

    .ledger {
      margin-bottom: $one-unit * 2;
      border-bottom: 1px solid $accent-color2;
      padding-bottom: $one-unit * 2;
      position: relative;

      & > button.close {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .attributes {
      position: relative;

      .btn.delete {
        display: none;
        position: absolute;
        top: $one-unit * 2;
        right: $one-unit * 2;
      }

      .btn.edit {
        position: absolute;
        top: $one-unit * 2;
        right: $one-unit * 2;
      }

      .condition {
        padding-right: 50px;
      }

      .notification {
        padding-right: 50px;
        margin-bottom: 1px;

        .icon {
          width: 30px;
          display: inline-block;
          text-align: right;
          margin-right: 5px;
        }

        .badge {
          width: $gutter * 1.5;
        }

        .notification-cc {
          display: block;
          color: lighten($black, 40);

          .badge {
            border: 1px solid $accent-color2;
            background: $white;
            color: $accent-color2;
          }
        }
      }

      .list-group {
        .list-group-item {
          display: block;

          h4 {
            .fa {
              margin-right: 5px;
            }
          }
        }
      }

      div + h3 {
        margin-top: $one-unit * 3;
      }

      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .btn.edit-attribute {
        position: absolute;
        top: 0;
        right: $one-unit;
        width: 40px;
      }
    }
  }
}
