#app.is-unauthenticated,
#app.is-unauthorised,
#app.is-deleted,
#app.is-missing {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $gutter;

    article {
      width: 30%;
      text-align: center;

      p {
        margin: 0;
      }
    }
  }
}