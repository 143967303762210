header#main-header {
    @include animate-state;
    @include flat;
    @include reset;
    background-color: $white;
    margin: 0 $one-unit * 2 0;
    padding: 0 $one-unit * 2;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: left;
    position: relative;
    height: round($gutter * 2.5);
    border-radius: $border-radius;

    &.main-header-has-banner {
        // margin-top: $gutter * 2;

        .alert {
            width: 100%;

            &.warning {
                background-color: $orange;
            }

            &.danger {
                background-color: $red;
            }

            .fas {
                font-size: round($font-size * 1.4);
                margin-right: $one-unit;
            }

            .close {
                color: $white;
                text-shadow: none;
            }

            @include depth;
            color: $white;
            font-size: $font-size * 1.2;
            font-weight: bold;
            position: absolute;
            top: -52px;
            left: 0;
        }
    }

    #like {
        border: none;

        position: absolute;
        background-color: transparent;
        top: 0;
        right: 0;
        width: $gutter * 3;
        display: flex;
        align-items: stretch;
        flex-direction: column;

        &.unliked {
            button {
                background-color: $hairline-grey;
                color: $mutedBlack;
            }
        }

        button {
            border: none;
            background-color: $blue;
            color: $white;
            height: $gutter;
            border-top-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }

    &.deleted {
        opacity: 0.5;
        text-decoration: line-through;
    }

    .main-header-stamp {
        margin-right: $one-unit * 2;

        .status {
            font-size: $gutter * 1.5;
            line-height: $gutter * 1.5;
        }
    }

    .main-header-titles {
        display: flex;
        flex-direction: column;
        margin-right: $one-unit * 4;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        address {
            line-height: 1;
            margin: 0;
        }
    }

    .main-header-metadata {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        #project-deadlines {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .project-deadline {
                display: flex;
                flex-direction: column;
                border-radius: $border-radius;
                margin-left: $one-unit * 4;
                justify-content: flex-end;
                align-items: center;
                text-align: center;

                .status {
                    margin-right: $one-unit;
                    display: inline;
                }

                .project-deadline-name {
                    font-family: $font-family-header;
                    font-size: $font-size;
                }

                .project-deadline-date {
                    font-size: $font-size / 1.4;
                    flex-basis: 100%;
                    color: $grey;
                }

                .project-deadline-tracking {
                    font-size: $font-size / 1.4;
                    flex-basis: 100%;
                    color: $grey;

                    &#mobile {
                        display: none;
                    }

                    &#secondary {
                        a {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    .main-header-datapoint {
        * {
            margin: 0;
        }

        display: flex;
        flex-direction: column;
        justify-content: normal;
    }

    &.project-closed {
        .main-header-metadata {
            #project-deadlines {
                display: none;
            }
        }
    }
}