.application-layout.templates {
	#tabs {
		article.ledgers.card {
			.card-block {
				.ledger {
					.card-block {
						padding: 0;
					}
				}
			}
		}
	}
}
