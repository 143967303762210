html {
	height: 100%;

	body {
		height: 100%;
		margin: 0;

		#app {
			height: 100%;
			width: 100%;
			grid-template-columns: 1fr;
			display: grid;
			&.application-layout {
				grid-template-rows: max-content 24px auto $gutter;
				grid-template-areas: "masthead" "breadcrumbs" "section" "footer";
			}
			&.with-breadcrumbs {
				grid-template-rows: max-content max-content max-content auto $gutter;
				grid-template-areas: "masthead" "breadcrumbs" "header" "section" "footer";
			}
			&.with-aside {
				grid-template-columns: 4fr $gutter * 10;
				grid-template-areas: "masthead masthead" "header header" "section aside" "footer footer";

				&.with-breadcrumbs {
					grid-template-areas: "masthead masthead" "breadcrumbs breadcrumbs" "header header" "section aside" "footer footer";
				}
			}

			&.home {
				grid-template-rows: max-content $gutter auto auto $gutter;
				grid-template-areas: "masthead masthead" "breadcrumbs breadcrumbs" "header header" "section aside" "footer footer";

				#headline-card {
					grid-area: header;
				}
			}

			> #masthead {
				grid-area: masthead;
			}

			#main-header {
				grid-area: header;
			}

			> #breadcrumbs {
				grid-area: breadcrumbs;
			}

			> aside {
				grid-area: aside;
			}

			> section {
				grid-area: section;
			}

			> footer {
				grid-area: footer;
			}
		}

		#app.wizard {
			grid-template-rows: $one-unit * 10 auto $gutter;
			grid-template-areas: "masthead" "section" "footer";

			> section {
				grid-area: section;
			}
		}
		&.is-missing,
		&.is-loading,
		&.is-warning,
		&.is-unauthorised,
		&.is-deleted,
		&.is-unauthenticated {
			#app {
				grid-template-columns: 1fr;
				grid-template-rows: $one-unit * 10 1fr $gutter;
				grid-template-areas: "masthead" "section" "footer";
			}
		}
		&.home {
			#app {
				grid-template-areas: "masthead masthead" "header header" "section aside" "footer footer";
				grid-template-rows: max-content 168px auto 32px;
				#headline-card {
					grid-area: header;
				}
			}
		}
	}
}
