#backgroundBox {
	fill: url(#Gradient1);
}
.stop1 {
	stop-color: red;
}
.stop2 {
	stop-color: black;
	stop-opacity: 0;
}
.stop3 {
	stop-color: blue;
}

#LeaseToOpenTable {
	margin-top: 50px;
	margin-left: 90px;
	thead {
		border-bottom: 1px solid;
		th {
			padding-bottom: 10px;
		}
	}
	tfoot {
		border-top: 1px solid;
		margin-bottom: 20px;
		margin-top: 20px;
		td {
			padding-top: 10px;
		}
	}
	td {
		padding-right: 50px;
	}
	:first-child td {
		padding-top: 10px;
	}
	:last-child td {
		padding-bottom: 10px;
	}
	.overdue {
		color: #f29a9a;
		font-weight: 700;
	}
	.early {
		color: #97d1ac;
		font-weight: 700;
	}
	.borderline {
		color: #f2994a;
		font-weight: 700;
	}
	.overdue-open {
		color: #f7605a;
		font-weight: 700;
	}
	.early-open {
		color: #394a58;
		font-weight: 700;
	}
	.earlier-open {
		color: #97d1ac;
		font-weight: 700;
	}
	.borderline-open {
		color: #f2994a;
		font-weight: 700;
	}
	a {
		text-decoration: underline !important;
		color: #00b1bf;
		&:hover {
			background-color: transparent;
		}
	}
}

#tooltip {
	background-color: white;
	border-radius: 4px;
	padding: 20px;
	text-align: center;
	pointer-events: none;
	.overdue {
		color: #f29a9a;
	}
	.early {
		color: #97d1ac;
	}
	.borderline {
		color: #f2994a;
	}
	.overdue-open {
		color: #f7605a;
	}
	.early-open {
		color: #394a58;
	}
	.earlier-open {
		color: #97d1ac;
	}
	.borderline-open {
		color: #f2994a;
	}
	.tooltip-heading {
		margin-bottom: 10px;
	}
}

#lto-chart-container {
	max-width: 900px;
	overflow-x: scroll;
}

.property-count {
	color: #a5a5a5 !important;
}

.property-count-td-padding {
	padding-right: 90px !important;
}
