@import "./tasks/show";
@import "./tasks/new";
@import "./tasks/list";
@import "./tasks/template";
@import "./tasks/add";
@import "./tasks/card";
@import "./tasks/analytics";

.task-edit-modal {
    width: 80%;
}