.loading {
  fill: none;
  max-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $one-unit;
  svg {
    @include animate-state;
    @include reset;
    width: $gutter * 3;
    height: $gutter * 3;

    g,
    path {
      @include animate-state;
      @include reset;
      opacity: 1;
    }

    g#border {
      @include animate-state(all, 32000ms);
      @include rotate;
      animation-duration: 4s;
      rect,
      circle,
      path,
      polygon {
        opacity: 1 !important;
        transform: scale(1) !important;
      }

      path.ball {
        fill: $red;
      }

      path.horizontal-arc {
        fill: $blue;
      }

      path.vertical-arc {
        fill: $dark-blue;
      }
    }
  }
  p {
    display: block;
    white-space: nowrap;
    color: $grey;
    font-size: round($font-size * 0.8);
    margin-top: $one-unit * 2;
  }
}
article.card {
  .loading {
    svg {
      path.ball {
        fill: $accent-color1;
      }

      path.horizontal-arc {
        fill: $accent-color1;
      }

      path.vertical-arc {
        fill: $accent-color1;
      }
    }
  }
}
#app.is-loading {
  height: 99vh;
  > .loading {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 100%;
    text-align: center;
    svg {
      margin: 0;
    }
    span {
      display: none;
    }
  }
}
