@mixin mobile-layout {
	.application-layout,
	.page,
	html {
		height: 100%;
	}

	.mobile-layout {
		fieldset {
			margin: 0;
		}
	}

	#app.session-layout {
		.container-fluid {
			width: 100%;

			article {
				header {
					@include animate-state;
					margin: 0;
				}

				form {
					.form-group {
						@include animate-state;

						.form-control {
							font-size: $font-size * 1.8;
						}
					}
				}
			}
		}
	}

	.ReactModal__Content {
		height: 90%;
	}

	html {
		height: 100%;

		body {
			height: 100%;
			margin: 0;
			background-color: $white !important;

			> #app {
				height: 100vh;
				display: grid;
				grid-template-columns: 1fr;
			}

			> .application-layout {
				height: 100vh;
				width: 100%;
				display: grid;
				grid-template-columns: 100vw !important;

				grid-row-gap: 0 !important;

				grid-template-areas: "masthead" "header" "section" !important;
				grid-template-rows: max-content max-content max-content !important;

				#breadcrumbs {
					display: none;
				}
				header#masthead {
					width: 100vw;
					border-bottom: 1px solid $hairline-grey;

					#ident {
						text-align: center;
						padding: 0;
						min-width: $gutter * 2.5;

						a {
							display: block;
							padding: $one-unit;

							#logo {
								width: $gutter * 1.5;
								height: $gutter * 1.5;
							}
						}
					}

					nav {
						&#primary {
							display: none;
						}

						&#secondary {
							display: none;
						}

						&#mobile {
							display: flex !important;
							justify-content: center !important;
						}
					}
				}

				&.home {
					.row {
						.col {
							flex-basis: 100%;
						}
					}

					#headline-card,
					aside {
						display: none;
					}

					#projects-by-property-card {
						display: none;
					}
				}

				#main-header {
					grid-area: header;
					margin: 0 $one-unit !important;
					padding: 0;

					// .main-header-toolbar {
					// 	display: none;
					// }

					.main-header-metadata {
						display: none;
					}

					#like {
						display: none;
					}
				}

				footer {
					display: none;
				}

				.workspace {
					margin: 0;

					.sort-filter-bar-container {
						display: none;
					}

					nav.pagination.grouped {
						display: none;
					}
				}
			}
		}
	}

	.workspace {
		border-top: 1px solid $hairline-grey;
		width: 100vw;

		#workflows-card div.workflow-status {
			display: none;
		}

		.list-group-component {
			.list-group-item {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				.stamp {
					order: 1;
				}

				> span {
					order: 2;
					display: flex;
					flex-direction: column !important;
					flex-wrap: nowrap;

					.list-group-item-parent {
						order: 2 !important;
						color: grey;

						.property-inline {
							h4 {
								font-size: $font-size;
							}

							h6 {
								display: none;
							}
						}
					}

					.list-group-item-heading {
						order: 1;
					}
				}

				.list-group-item-statistics {
					display: none !important;
				}

				.list-group-item-datapoints {
					display: none !important;
				}

				.badge {
					display: none !important;
				}

				.disclosure {
					order: 4;
				}
			}
		}
	}

	.document-list-group.documents-grid-view .list-group-section .list-group-component-documents {
		display: flex;
		flex-direction: column !important;
	}

	.show.property {
		.nav-item {
			.nav-parties,
			.nav-documents,
			.nav-comments,
			.nav-dashboard,
			i {
				display: none;
			}
		}
		.map {
			display: none;
		}
		.col {
			width: 100%;
		}
	}
	.show.building {
		.nav-item {
			.nav-documents,
			.nav-parties,
			.nav-dashboard,
			i {
				display: none;
			}
		}
	}
	.show.tasks {
		.nav-item {
			.nav-parties,
			.nav-dashboard,
			.nav-activity {
				display: none;
			}
		}
	}
	.show.inspections {
		.nav-item {
			.nav-details {
				display: none;
			}
		}
		.row .col:only-child {
			min-width: 100% !important;
		}
	}

	aside {
		display: none;
	}

	.greeting {
		display: none;
	}

	#home-today-card {
		display: none;
	}

	/*THIS IS A MESS. EMBRACING CHAOS....*/

	html {
		body {
			#masthead {
				.branch-warning {
					display: none !important;
				}
			}

			#feed-card {
				.activity-item a.activity-item-link .activity-item-avatar {
					display: none !important;
				}

				.activity-item a.activity-item-link .activity-item-container {
					width: inherit;
				}
			}
			&.home {
				#app {
					grid-template-areas: "masthead masthead" "section aside" "footer footer";
					grid-template-rows: max-content auto 32px;
					#headline-card {
						display: none;
					}
				}
			}
		}
	}
}
/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
	@include mobile-layout;
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
	@include mobile-layout;
}

/* ----------- iPhone 6 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	@include mobile-layout;
}

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
	@include mobile-layout;
}
/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
	@include mobile-layout;
}
