.application-layout {
	#masthead {
		position: relative;
		display: flex;
		flex-direction: column;
		background: $white;
		align-self: auto;
		overflow-x: hidden;
		overflow-y: hidden;
		// div {
		// 	position: relative;
		// }
		.notice {
			flex-basis: 100%;
		}
		.global-nav {
			display: flex;
			flex-direction: row;
			max-height: $one-unit * 10;
		}

		#ident {
			padding: $one-unit * 2;
			text-align: center;

			a {
				border: none;
				vertical-align: top;

				&:hover {
					border: none;
					background: none;
				}

				#logo {
					@include animate-state;
					@include reset;
					width: $gutter * 1.5;
					height: $gutter * 1.5;

					// path {
					//   fill: $accent-color2 !important;
					// }
					// &:hover {
					path.letter {
						fill: $dark-blue !important;
					}

					path.ball {
						fill: $red !important;
					}

					path.horizontal-arc {
						fill: $blue !important;
					}

					path.vertical-arc {
						fill: $dark-blue !important;
					}

					// }
				}
			}
		}

		nav {
			position: static;
			display: flex;
			flex-direction: row;
			align-items: center;

			a,
			button {
				&.unread {
					i {
						color: $blue;
					}
				}

				box-shadow: none;
				cursor: pointer;
				background: none;
				font-weight: 400;
				text-rendering: optimizeLegibility;
				font-size: round($font-size * 0.9);
				font-family: $font-family-body;
				position: relative;
				text-align: center;
				vertical-align: baseline;
				color: $accent-color2;
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				min-width: $gutter * 2.5;
				margin: $one-unit 0;
				border: none;
				border-bottom: $one-unit/4 solid rgba(255, 255, 255, 0);

				.fa-circle {
					position: absolute;
					top: $one-unit * 2;
					right: $one-unit * 2;
				}

				span {
					display: inline-block;

					span {
						display: block;
					}
				}

				.fa {
					font-size: $font-size;
					color: $accent-color2;
				}

				&:focus {
					text-decoration: none;
				}

				&.active {
					color: $blue;
					border-bottom-color: $accent-blue;
				}

				&:hover {
					background-color: $blue;
					color: $white;
					border-bottom-color: $accent-blue;

					.fa {
						color: $white;
					}

					.badge {
						background-color: $white;
						color: $accent-blue;
						box-shadow: none;
					}
				}
			}

			&#mobile {
				display: none;
			}

			&#secondary {
				a {
					width: auto;
				}
				margin-left: auto;
			}
			.notification-dot {
				color: $info-mid;
			}
		}
		.branch-warning {
			display: flex;
			align-items: center;
			align-content: center;
			margin-left: $one-unit * 4;
			> p {
				align-self: center;
				background-color: $warning-light;
				text-transform: uppercase;
				padding: 10px;
				margin-bottom: 0;
				font-weight: bold;
			}
		}
	}
}
