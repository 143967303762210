.card {
	.card-block {
		.sort-filter-bar-container {
			position: relative;
			display: flex;
			flex-direction: row;
			margin-bottom: $one-unit;
			border-radius: $border-radius;
			align-items: center;
			justify-content: space-between;
			border: 1px solid $hairline-grey;
			height: $gutter;
			padding: 0 $one-unit;

			dl {
				flex: 1;
				display: flex;
				margin: 0;

				dd,
				dt {
					font-size: round($font-size * 0.8);
					color: $grey;
					margin: 0 $one-unit/4 0 0;
					word-wrap: none;
				}

				dd {
					margin: 0 $one-unit 0 0;
				}
			}

			> button {
				cursor: pointer;

				span {
					margin-left: $one-unit * 2;
				}

				font-size: $font-size * 0.8;
				color: $grey;
				border-radius: 0;
				padding: $one-unit $one-unit * 2;
				border: none;
				border-left: 1px solid $hairline-grey;
				background: none;

				&:hover {
					background: $blue;
					color: $white;
				}
			}

			.sort-filter-bar {
				> button {
					position: absolute;
					top: $one-unit;
					right: $one-unit * 2;
				}

				@include depth;
				margin-bottom: $one-unit;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				position: absolute;
				right: $gutter;
				padding: $one-unit $gutter * 1.5 $one-unit $one-unit * 2;
				background-color: $white;
				border: 1px solid $hairline-grey;
				border-radius: $border-radius;
				z-index: 3;

				label {
					width: $gutter * 2;
					font-size: $font-size * 0.9;
					color: $accent-color2;
					white-space: nowrap;
					margin: 2px;
				}

				.sort,
				.filter,
				.view {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 4px;

					.btn-toolbar {
						flex: 1;

						.btn-group {
							display: flex;
							margin-left: 5px;
							padding-left: 10px;

							.btn {
								cursor: pointer;

								&:focus {
									box-shadow: none;
								}

								&:hover {
									@include depth;
									background: $blue;
									border-color: $blue;
									color: $white;
								}

								&.active {
									background: $blue;
									border-color: $blue;
									color: $white;
								}

								&:first-child:not(:last-child) {
									border-radius: 3px 0 0 3px;
									padding-left: $one-unit;
								}

								&:last-child:not(:first-child) {
									border-radius: 0 3px 3px 0;
									padding-right: $one-unit;
								}

								width: auto;
								border-color: $hairline-grey;
								color: $accent-color2;
							}
						}
					}
				}
			}
		}
	}
}
