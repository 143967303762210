html {
	height: 100%;
	body {
		height: 100%;
		margin: 0;
		> .application-layout.panel-layout {
			grid-template-rows: $gutter * 3 $gutter * 1.5 auto $gutter;
			grid-template-areas: "header" "breadcrumbs" "section" "footer";
			grid-template-columns: 100%;
			section {
				box-shadow: 0 $one-unit/4 $one-unit $one-unit/4 rgba($black, 0.01);
				border-bottom: 1px solid darken($hairline-grey, 2);
				display: flex;
				flex-direction: row;
				align-items: stretch;
				overflow: auto;
				margin: 0 $one-unit;
				border-radius: $border-radius;
				> *:nth-child(1) {
					overflow-x: hidden;
					flex-basis: 25%;
				}
				> *:nth-child(2) {
					overflow-x: hidden;
					flex-basis: 25%;
				}
				> *:nth-child(3) {
					flex: 1 1 50%;
				}
				> article {
					@include reset;
					div.card-block {
						padding: 0;
						overflow: auto;
						display: flex;
						flex-direction: column;
						h2 {
							margin: $one-unit * 2;
							padding: 0;
						}
						.search-component {
							height: round($gutter * 1.25);
							flex: none;
							padding: 0;
							border-bottom: 1px solid $hairline-grey;
							form.form-inline {
								width: 100%;
								input {
									border: none;
									width: 100%;
								}
							}
						}
						.search-results {
							p.small {
								text-align: left;
								padding: $one-unit;
								margin-bottom: $one-unit;
								span.query {
									background-color: yellow;
								}
								&:first-of-type {
									padding-bottom: 0;
									margin-bottom: 0;
								}
							}
						}
						.folder-groups {
							flex: 1;
							overflow-y: auto;
							padding-top: $one-unit;
							.folder-group {
								padding-top: $one-unit * 2;
								&:first-of-type {
									padding: 0;
									> h6 {
										border: none;
										padding-top: 0;
									}
								}
								> h6 {
									margin: 0;
									padding: $one-unit $one-unit * 2 $one-unit/4;
									border-top: 1px solid darken($hairline-grey, 5);
								}
								.list-group-component {
									.list-group-section {
										.list-group {
											.list-group-item {
												cursor: pointer;
												flex-direction: row;
												align-items: flex-start;
												font-size: $font-size;
												padding: $one-unit $one-unit * 2;
												position: relative;
												background: none;
												display: flex;
												&.unread {
													font-weight: bold;
													&::before {
														content: "\f111 ";
														color: $red;
														display: inline-block;
														position: absolute;
														z-index: 2;
														top: $one-unit;
														right: $one-unit;
														font: normal normal normal 10px/1 FontAwesome;
													}
												}
												&.active {
													border-color: $blue;
													background-color: $blue;
													color: $white;
													* {
														color: $white;
													}
													.badge {
														background-color: $white;
														color: $blue;
													}
													&.unread {
														&::before {
															color: $white;
														}
													}
												}
												&:hover {
													border-color: lighten($blue, 5);
													background-color: lighten($blue, 5);
													color: $white;
													* {
														color: $white;
													}
													.badge {
														background-color: $white;
														color: $blue;
													}
													&.unread {
														&::before {
															color: $white;
														}
													}
												}
												h3,
												h4,
												h5,
												h6 {
													margin: 0 0 $one-unit/4;
													display: block;
													text-transform: none;
												}
												p {
													font-weight: 200;
													margin: 0;
												}
												.badge {
													background-color: $blue;
													color: $white;
													position: absolute;
													right: $one-unit;
													top: $one-unit;
												}
												.status {
													margin-right: $one-unit;
												}
												.formatted-date {
													font-weight: 200;
													font-size: round($font-size * 0.8);
												}
											}
										}
									}
								}
							}
						}
						&.empty-mailbox {
							.card-block {
								display: flex;
								flex-direction: column;
								justify-content: center;
								.empty-state {
									margin: 0;
									p {
										text-align: center;
									}
								}
							}
						}
					}
				}
			}
		}
		@mixin mobile-layout {
			.application-layout.panel-layout {
				section {
					> article {
						&:nth-child(1) {
							display: none;
						}
					}
				}
			}
		}
	}
}
