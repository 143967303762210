//BASE STATUS
.status {
	color: $grey;
}

.project-closed {
	.status {
		color: $dark-blue !important;
	}
}
.status {
	font-size: $font-size * 3;
}

// .task-ahead,
// .phase-ahead,
// .project-ahead {
// 	.status {
// 		color: $green;
// 	}
// }
// .task-behind,
// .phase-behind,
// .project-behind {
// 	.status {
// 		color: $red;
// 	}
// }

// .project-behind {
// 	.task-behind {
// 		.status {
// 			color: $red;
// 		}
// 	}
// }

// .project-ahead,
// .project-behind {
// 	.task-ahead {
// 		.status {
// 			color: $green;
// 		}
// 	}
// }

// .project-closed,
// .phase-closed,
// .task-closed {
// 	.status {
// 		color: $green;
// 	}
// }

// .project-closed {
// 	.status {
// 		color: $dark-blue !important;
// 	}
// }

// .project-behind,
// .project-behind {
// 	.phase-behind {
// 		.status {
// 			color: $red;
// 		}
// 	}
// }

// .project-behind,
// .project-ahead {
// 	.deadline-behind {
// 		.status {
// 			color: $red;
// 		}
// 	}
// 	.deadline-ahead {
// 		.status {
// 			color: $green;
// 		}
// 	}
// 	.deadline-closed {
// 		.status {
// 			color: $green;
// 		}
// 	}
// 	.phase-ahead {
// 		.status {
// 			color: $green;
// 		}
// 	}
// }
