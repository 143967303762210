.unfinished {
	background-color: rebeccapurple;
	outline: 1px solid rebeccapurple;
	color: white;
}

.todo {
	background-color: rebeccapurple;
	outline: 1px solid rebeccapurple;
	color: white;
	border-radius: $border-radius;
	padding: 3px;
	font-weight: 600;
}

article.placeholder {
	min-height: $gutter * 5;
	border: 1px solid rebeccapurple;
	display: flex;
	h1 {
		font-size: 40px;
		font-weight: 900;
		color: $hairline-grey;
		margin: 0;
		flex: 1;
		flex-direction: row;
		align-items: center;
		font-size: larger;
		font-weight: bold;
	}
}

span.debug {
	display: inline;
	color: rebeccapurple;
	font-weight: bold;
	border: none;
	padding: 0;
}

div#debug {
	display: block;
	border: 1px solid rebeccapurple !important;
	background: #f2ecf9 !important;
	padding: 8px;
	color: rebeccapurple;
	border-radius: 2px;
	display: block;
	position: fixed;
	bottom: 16px;
	left: 16px;
	height: 30%;
	width: 50%;
	overflow-y: scroll;
	overflow-x: hidden;

	* {
		color: rebeccapurple !important;
		border-color: lighten(rebeccapurple, 35%) !important;
	}
	pre {
		display: block;
		white-space: pre-wrap;
	}
}

#activity {
	@include depth;
	z-index: 9999;
	position: fixed;
	top: 5px;
	right: 5px;
	width: 160px;
	opacity: 0.1;
	ul {
		@include reset;
		li {
			@include reset;
			background-color: rebeccapurple;
			color: white;
			margin: 1px;
			padding: 5px;
			text-align: center;
		}
	}
}
