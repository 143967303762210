.form-group {
	.react-select {
		width: 100%;
	}

	.react-select__control {
		.react-select__indicator-separator {
			background-color: $hairline-grey;
		}

		svg {
			display: block;

			path {
				opacity: 1;
				transform: none;
			}
		}

		&.react-select__control--is-focused {
			border-color: $blue;
			box-shadow: none;
		}

		border-color: $hairline-grey;
		border-radius: $border-radius;
		background-color: $white;

		.react-select__value-container {
			.react-select__placeholder {
				color: $black;
				opacity: 0.3;
				font-size: $font-size * 0.9;
			}

			.react-select__indicators {
				outline: 1px solid rebeccapurple;
			}
		}
	}

	.react-select__menu {
		z-index: 10;

		.react-select__menu-list {
			.react-select__option {
				&:nth-child(even) {
					background-color: lighten($bg-color, 5%);
				}

				&.react-select__option--is-selected {
					background-color: darken($blue, 10%);
					color: $white;
				}

				&.react-select__option--is-focused {
					background-color: $blue;
					color: $white;
				}

				* {
					margin: 0 !important;
				}

				address {
					font-size: round($font-size * 0.8);
				}

				h5 {
					font-weight: bold;
				}
			}
		}
	}
}
