#project-documents-card {
	.card-block {
		display: flex;
		.project-documents-resources {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				margin-left: $one-unit;
				font-size: $one-unit * 2;
				.fa-plus-square {
					color: $mutedBlack;
				}
				li {
					color: $grey;
					margin-top: $one-unit;
					&.selected {
						> button {
							background-color: $hairline-grey;
						}
					}
					span {
						margin-left: $one-unit/2;
						color: $mutedBlack;
					}
					button {
						border: none;
						background: none;
						padding: 0;
						margin: 0;

						&:hover {
							background-color: $hairline-grey;
							cursor: pointer;
						}
						&.selected {
							background-color: $hairline-grey;
							ul {
								background-color: $white;
							}
						}
					}
				}
			}
			> ul {
				margin-left: 0;
			}

			flex-basis: $gutter * 8;
			margin-right: $gutter;
		}
		.project-documents-documents {
			.breadcrumbs {
				padding-left: 0;
				position: absolute;
			}
			flex: 2;
		}
	}
}
