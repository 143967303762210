#notice {
	background-color: $warning-light;
	text-align: left;
	padding: $one-unit * 2;
	font-size: $font-size;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	.notice-content {
		flex-basis: 90%;
	}
	button {
		background-color: transparent;
		border: none;
		flex-basis: 5%;
	}
}

#notice.hide {
	display: none;
}
