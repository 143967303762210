.my-progress-container {
	position: relative;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
		"Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.my-progress-container .my-progress-bar {
	width: 100%;
	position: relative;
}
.my-progress-container .my-progress-bar .circle-bg {
	stroke: #efefef;
	fill: none;
}
.my-progress-container .my-progress-bar .circle-path {
	fill: none;
	stroke-linecap: round;
	transform: rotate(-90deg);
	transform-origin: center;
	transition: stroke-dasharray 0.5s;
}
.my-progress-container .my-progress-bar .progress-text {
	fill: #000;
	text-anchor: middle;
	// dominant-baseline: middle; - this is throwing errors
	font-size: 2px;
}
.my-progress-container .my-progress-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.status-legend {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	text-align: center;
	.ahead svg {
		color: $green;
	}
	.behind svg {
		color: $red;
	}
	> div {
		margin-right: $one-unit * 2;
		display: flex;
		flex-direction: row;
		.status {
			font-size: $font-size;
			margin-right: $one-unit/2;
		}
	}
}
