//BASE STATUS
.status {
	color: $grey;
}

.task-ahead {
	.status {
		color: $green;
	}
	&.task-at_risk {
		.status {
			color: $orange;
		}
	}
}

.task-behind {
	.status {
		color: $red;
	}
}

.project-behind {
	.task-behind {
		.status {
			color: $red;
		}
	}
}

.project-ahead,
.project-behind {
	.task-ahead {
		.status {
			color: $green;
		}
		&.task-at_risk {
			.status {
				color: $orange;
			}
		}
	}
}

.task-closed {
	.status {
		color: $green !important;
	}
}
