.ReactModalPortal {
  .ReactModal__Overlay {
    .ReactModal__Content.task-modal {
      padding: 0 !important;
      width: 90%;
      min-height: 340px;
      top: 5%;
      left: 5%;

      &.task-closed {
        header {
          background-color: $green;
          border: none;
          padding-bottom: $one-unit * 2;

          * {
            color: $white;
          }
        }
      }

      header {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $accent-color2;
        padding: $one-unit * 2;
        min-height: 72px;
        margin-bottom: 0;

        svg {
          width: 48px;
          height: 48px;
          margin-right: $one-unit;
        }

        .title {
          flex: 1;

          h3 {
            margin-bottom: 0px;
          }

          h1 {
            margin-bottom: 0;
          }

          p {
            font-weight: 100;
            margin-bottom: 0;
          }
        }
      }

      section {
        margin-bottom: $one-unit * 2;
        padding: 0 $one-unit * 2;
        display: flex;
        flex-direction: row;

        .can-override {
          font-weight: 100;
          margin-bottom: $one-unit;
        }

        .task-comments {
          flex: 1 1 50%;
          display: flex;
          border-left: 1px solid $white;
          padding-left: $one-unit * 2;
          position: relative;
          flex-direction: column;
          align-items: stretch;
          margin-bottom: -$one-unit * 2;

          .comment-parties {
            margin-top: $one-unit * 2;
            font-weight: 100;
            font-size: round($font-size * 0.8);
          }
        }

        .status {
          flex: 1 1 50%;
          display: flex;
          flex-direction: column;
          margin-right: $one-unit * 2;

          fieldset {
            margin: 0;
            border: none;
          }

          .form-group:last-child {
            margin-bottom: 0;
          }

          .form-group label {
            font-size: 1.1rem;
            color: rgba(21, 25, 28, 1);
          }

          .status-heading {
            align-self: flex-start;
            flex: 1;
          }

          .status-description {
            padding-top: $one-unit * 2;

            p {
              font-weight: 200;
            }
          }

          .loader {
            margin: 0;


            &.loader-unauthorised,
            &.loader-unavailable {
              background-color: $orange;
              border-radius: $one-unit;
            }

          }

          .status-conditions {
            flex: 100;
            overflow-y: auto;
            position: relative;
            // left: $gutter * -0.5;
            // width: calc(100% + ($gutter * -0.5));

            .list-group-item {
              padding: $one-unit * 2;
              border: none;
            }

            .status-closed {
              padding: $gutter;
              text-align: center;

              p {
                font-weight: 200;
              }

              svg {
                g.circle {
                  circle.shadow {
                    fill: $green;
                  }

                  g.check {
                    polygon {
                      fill: $white;
                    }
                  }
                }
              }
            }
          }

          footer {
            align-self: flex-end;
            border-top: 1px solid $accent-color2;

            .incomplete-conditions {
              color: $black;
              font-weight: 200;
              font-size: $font-size * 1.05;
              background-color: $white;
            }

            .task-dependencies {
              padding-top: $one-unit;
              color: $black;
              font-weight: 200;
              font-size: $font-size * 1.05;

              a {
                margin: -3px 3px 0;
                border-radius: $one-unit;
                padding: 3px 10px;
                border: 1px solid $orange;
                background-color: $white;
                color: $orange;
                font-weight: 600;
                white-space: nowrap;

                &.overdue {
                  color: $white;
                  border: none;
                  background-color: $red;
                }

                &.closed {
                  color: $white;
                  border: none;
                  background-color: $green;
                }
              }
            }
          }
        }
      }
    }
  }
}