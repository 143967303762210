svg#logo {
  g,
  path,
  polygon {
    @include animate-state;
    opacity: 1;
  }

  &:hover {
    g#border {
      @include animate-state(all, 16000ms);
      transform: rotate(180deg) !important;
    }
  }

  g#border {
    @include animate-state(all, 5000ms);
    transform-origin: center;
    transform: scale(1);

    path {
      transform: scale(1);
    }

    path.ball {
      fill: $red;
    }

    path.horizontal-arc {
      fill: $blue;
    }

    path.vertical-arc {
      fill: $white;
    }
  }

  g#letters {
    @include animate-state(all, 5000ms);
    path {
      transform: scale(1);
    }
    path.letter {
      @include animate-state(all, 5000ms);
    }

    path#letter-c,
    path#letter-t {
      fill: $blue;
    }

    path#letter-p {
      fill: $white;
    }
  }
}
