.application-layout {
	.datapoint {
		@include animate-state;
		position: relative;
		flex: 1;
		background: $bg-color;
		font-family: $font-family-header;
		display: flex;
		flex-direction: row;
		padding: $one-unit $one-unit * 2;
		border-radius: $border-radius;
		align-items: center;

		.datapoint-name {
			flex: 1;
			font-weight: 300;
		}

		.datapoint-value {
			font-size: $font-size * 2;
			font-weight: 700;
		}

		.fa {
			color: $grey;
		}

		&.behind {
			color: $red;
		}

		&.complete {
			color: $green;
		}

		&.archived {
			color: $dark-blue;
		}

		&.at_risk {
			color: $orange;
		}

		&.ahead {
			color: $green;
		}

		&.property {
			.legend {
				position: absolute;
				top: $one-unit;
				left: $one-unit;

				.fa {
					color: $white;
				}
			}
		}
	}

	a.datapoint {
		&:hover,
		&.active {
			@include animate-state;
			background-color: $blue;

			.fa {
				color: $white;
			}

			&::after {
				opacity: 1;
			}
		}
	}

	article.card.datapoints {
		.card-block {
			.datapoints-collection {
				display: flex;
			}
		}

		&.datapoints-grid {
			.card-block {
				.datapoints-collection {
					margin: 0 $gutter/-8;
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));

					.datapoint {
						flex-direction: column-reverse;
						margin: $one-unit/4;
					}
				}
			}
		}
	}
}

.application-layout {
	.col {
		.datapoints {
			.card-block {
				.datapoints-collection {
					flex-direction: column;

					.datapoint {
						margin-bottom: $one-unit;
						flex-direction: row;

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.datapoints {
		.card-block {
			.datapoints-collection {
				flex-direction: row;

				.datapoint {
					.datapoint-value {
						width: $gutter * 2;
						text-align: center;
					}

					margin: 0 $one-unit/4;
					flex-direction: row-reverse;
				}
			}
		}
	}
}
