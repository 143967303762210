.input-wysiwyg {
    margin-top: $one-unit;

    &.input-disabled {
        .input-wysiwyg-container {
            background-color: $accent-color2;
            border: 1px solid $accent-color2;
            color: $grey;
        }
    }

    .input-wysiwyg-container {
        width: 100%;
        border: 1px solid lighten($accent-color2, 2);
        padding: $one-unit * 2;
    }

    &.has-danger {
        .input-wysiwyg-editor {
            &:hover {
                border-color: $red;
            }

            border-color: $red;
        }
    }

    .input-wysiwyg-editor {
        @include form-control;
        line-height: inherit;
        width: 100%;
        position: relative;
        padding-bottom: $gutter * 1.3;


        .DraftEditor-root {
            .public-DraftEditorPlaceholder-inner {
                font-weight: 100;
                color: #dfdfdf;
                z-index: -1;
                position: absolute;
                left: $one-unit;
                top: $one-unit;
            }

            padding-bottom: $one-unit;

            .DraftEditor-editorContainer {
                .public-DraftEditor-content {
                    padding: 0;
                    min-height: $gutter * 2;
                    margin-bottom: $one-unit;

                    .public-DraftStyleDefault-block {
                        margin-bottom: $one-unit;
                    }

                    &.__has-no-content:after {
                        content: attr(data-placeholder);
                        color: $accent-color2;
                        cursor: text;
                        position: absolute;
                        top: 0;
                    }

                    .draftJsMentionPlugin__mention__29BEd {
                        display: inline-block;
                        padding: 3px;
                        border-radius: 3px;
                        color: #677584;
                        background: #edf5fd;
                        outline: 0;
                    }

                    blockquote {
                        margin-left: $one-unit * 2;
                        border-left: 5px solid $grey;
                        padding: $one-unit;
                        padding-left: $one-unit * 2;
                        font-family: serif;
                        font-size: $font-size * 2;
                        font-weight: 100;

                        div {
                            display: inline-block;
                        }

                        &::before {
                            content: '“';
                        }

                        &::after {
                            content: '”';
                        }
                    }

                    code {
                        color: $red;
                        background-color: $white;
                    }

                    aside {
                        float: right;
                        border: 1px solid $accent-color2;
                        font-weight: 200;
                        border-radius: $one-unit;
                        padding: $one-unit * 2;
                        margin: $one-unit;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .input-wysiwyg-toolbar {
            position: absolute;
            bottom: $one-unit;
            padding-top: $one-unit;
            margin-top: $one-unit;
            left: $one-unit;
            right: $one-unit;
            display: flex;
            flex-direction: row;
            border-top: 1px solid $hairline-grey;

            .draftJsStickerPlugin__select__30KbO {
                button.draftJsStickerPlugin__selectButton__3voOt {
                    @include toolbar-button;
                    height: auto;
                }
            }

            .draftJsEmojiPlugin__emojiSelect__34S1B {
                button {
                    @include toolbar-button;
                }
            }

            .input-wysiwyg-toolbar-controls {
                flex: 1;

                button.input-wysiwyg-toolbar-button {
                    @include toolbar-button;
                }
            }
        }
    }
}

.has-danger {
    .input-wysiwyg {
        .input-wysiwyg-container {
            border-color: lighten($red, 20);
        }
    }
}