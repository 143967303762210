* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	.input-wysiwyg-editor {
		cursor: text !important;
	}
	scroll-behavior: smooth;

	&.blue {
		background-color: $blue;
	}

	body {
		font-weight: 400;
		font-size: $font-size;
		font-family: $font-family-body;
		background-color: $bg-color;
		color: $black;
		line-height: 1.4;
		margin: 0;
		padding: 0;

		// ::selection {
		// 	background-color: $blue;
		// 	color: $white;
		// 	padding: $one-unit;
		// }

		hr {
			border-color: $hairline-grey;
		}

		button,
		input,
		optgroup,
		select,
		textarea {
			font-family: $font-family-body;

			&:focus {
				outline: none;
			}
		}

		a {
			color: $black;

			&:hover {
				color: $white;
				background-color: $blue;
				text-decoration: none;
			}
		}

		.small,
		small {
			opacity: 0.8;
			font-weight: 300;
		}

		code {
			color: $red;
			background-color: $white;

			&::selection {
				background-color: $red;
				color: $white;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $font-family-header;
			font-weight: 200;
		}

		h1 {
			@include header(1.5);
		}

		h2 {
			font-weight: 600;
			@include header(1.4);
		}

		h3 {
			@include header(1.3);
		}

		h4 {
			@include header(1.2);
		}

		h5 {
			@include header(1);
		}

		h6 {
			@include header(0.9);
		}

		h6 + span + small {
			position: relative;
			top: $gutter/-4;
			display: block;
		}

		p {
			line-height: 1.3;

			&.description {
				p:first-child {
					font-size: round($font-size * 1.3);
				}
			}
		}

		/* 414px == iPhone 6+ Portrait */
		.btn {
			border-radius: $border-radius;
		}

		section {
			.list-group-item {
				.unread {
					color: $blue;
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 10px;
				}
			}
		}

		.warn {
			border: 1px solid $orange;
			border-radius: $border-radius;
			margin-bottom: $gutter;

			h5 {
				background-color: $orange;
				padding: $one-unit;
				font-weight: 600;
				color: $white;
			}

			.warn-body {
				padding: $one-unit;

				* {
					color: $black;
				}

				dl.definition-list {
					div.definition-item {
						dt.definition-term {
							color: $black;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 414px) {
	.page-header-avatar {
		display: none;
	}

	.avatar {
		display: none !important;
	}
}

.media-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}
}

.byline {
	font-size: round($font-size * 1.1);
	margin-top: $one-unit;
	color: #aeaeae;
}

.avatar {
	float: left;
	margin-right: 8px;
	width: 48px;
	height: 48px;
}

.mention {
	display: inline-block;
	color: $blue;
}

.hashtag {
	display: inline-block;
	color: $blue;
}

button.add {
	position: absolute;
	top: 10px;
	z-index: 2;
	right: 20px;
}

.SingleDatePicker {
	display: block;
}

.SingleDatePickerInput {
	border-radius: $border-radius;
}

.has-danger .SingleDatePickerInput {
	border-color: $red;
}

.DateInput {
	width: 100%;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
}

.DayPicker__week-headers {
	display: none;
}

.CalendarMonth--horizontal {
	margin-top: 56px;
}

.transition-container {
	height: 350px;
}

.DateInput__display-text--focused {
	background-color: transparent;
	border-color: transparent;
	color: #333;
}

.rct-Aside-row {
	font-weight: 600;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: -0.01rem;
	opacity: 0.5;
	font-size: 12px;
	color: rgb(55, 58, 60);
}

.file-input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.muted {
	color: $mutedBlack;
}

.search {
	position: relative;
}

.search input {
	z-index: 2;
}

.search .list-group {
	height: auto;
	position: absolute;
	top: 60px;
	z-index: 1;
	width: 100%;
}

.search .list-group .list-group-item {
	cursor: pointer;
}

.search .list-group .list-group-item mark {
	padding: 0;
}

.search #search-status {
	position: absolute;
	right: 15px;
	top: 32px;
	z-index: 3;
	opacity: 0.5;
}

mark {
	background-color: #25bed5;
}

.highlighted {
	background-color: #ffffd9;
}

.tenancy-item {
	margin-bottom: 2rem;
	padding-bottom: $font-size;
	border-bottom: 1px solid #efefef;
}

.failed {
	background: #ffffff;
	color: #fc4237;
	border: 1px solid #fc4237;
	margin: $font-size 0;
	padding: 5rem;
	text-align: center;
	border-radius: $border-radius;
}

.failed .fa {
	color: #fc4237;
}

.btn-xs {
	font-size: $font-size * 0.7;
}

.input-group .form-control {
	z-index: 1;
}

.Select .Select-menu-outer {
	z-index: 3;
}

.Select--single > .Select-control .Select-value,
.Select-placeholder {
	padding-left: 0 !important;
}

.progress {
	border-radius: $border-radius;

	.progress-bar {
		background-color: $orange;
	}
}

input[type="number"] {
	text-align: center;
}

input[type="radio"]:checked {
	background-color: #000000 !important; //bg-blue
	color: #000 !important;
}

label > input[type="radio"] {
	display: none;
}

label > input[type="radio"] + *::before {
	content: "";
	display: inline-block;
	vertical-align: bottom;
	width: 20px;
	height: 20px;
	margin-right: 10px;
	border-radius: 50%;
	border-style: solid;
	border-width: 0.1rem;
	border-color: gray;
}

label > input[type="radio"]:checked + *::before {
	background: radial-gradient(#00c6d7 0%, #00c6d7 40%, transparent 50%, transparent);
	border-color: #00c6d7;
}

.flex-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

address {
	span {
		margin-right: $gutter/16;
	}
}

.error {
	p {
		line-height: 48px;
	}
}

#app {
	.row {
		margin: 0 !important;

		.col {
			flex: 1;
			max-width: 100%;
			padding: 0 $one-unit * 2;
			border-right: 1px solid $hairline-grey;

			&.icon-col {
				display: flex;
				align-items: flex-start;
				justify-content: center;
				width: $gutter * 2;
				text-align: center;
				flex: none;

				i {
					font-size: $gutter;
					display: block;
				}
			}

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				border: none;
				padding-right: 0;
			}

			&:only-child {
				width: 33%;
				min-width: $gutter * 15;
				flex-grow: unset;
				flex-basis: unset;

				> button {
					float: right;
				}
			}
		}
	}
}

.alert {
	margin: 0;
	border-radius: $border-radius;
	padding: $one-unit * 2;

	&.alert-warning {
		border-color: $yellow;
		background-color: lighten($yellow, 20%);
		color: mix($yellow, $black, 1);
		a {
			color: mix($yellow, $black, 1);
			&:hover {
				background-color: $yellow;
			}
		}
	}

	&.alert-danger {
		border-color: $red;
		background-color: $red;
		color: $white;
		a {
			color: $white;
			&:hover {
				background-color: $red;
			}
		}
	}

	&.alert-light {
		border: 1px solid $hairline-grey;
		color: $accent-color1;
	}

	&.alert-info {
		color: darken($blue, 10%);
		border-color: $blue;
		background-color: lighten($blue, 50);
		a {
			color: darken($blue, 10%);
		}
	}

	p {
		display: inline;
	}
}

h2 + div.alert {
	margin: 0;

	&:last-child {
		margin: 0;
	}
}

.dragging {
	background-color: $blue !important;
	color: $white;

	* {
		color: $white;
	}
}

.feature-disabled {
	background-color: $hairline-grey;
	border: 4px solid $grey;
	padding: $one-unit * 4;
}

.details {
	&.card {
		dl.definition-list {
			.definition-item {
				.definition-item-body {
					display: flex;
					font-family: $font-family-body;
					dt {
						text-transform: capitalize;
						margin-right: $one-unit;
						font-weight: 700 !important;
						font-family: $font-family-body;
					}
					dd {
						display: flex;
						.formatted-date {
							margin-right: $one-unit;
						}
					}
				}
			}
		}
	}
}

.search-results  {
	max-height: 75vh;
  	overflow-y: auto;
	ul>li>a:hover {
		background: none;
    	color: inherit;
	}
    
}

.search-filter ul>li.active {
    background-color: #E2EEFF;
}

.search-filter ul>li.active>button {
    color: #3179DB !important;
}

.line-with-category-text {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0 0;
	color: grey;
	padding: 10px 10px 0;

	.line {
		flex-grow: 1;
		height: 1px;
		background-color: grey;
	}
	
	.text {
		padding: 0 10px;
	}
}


////***** TODO : some of this 'common' css should be pulled into component-level css files and component folders****////
