.flyout {
	position: relative;
	// z-index: 200;
	order: 99;

	button {
		&:hover,
		&:focus {
			border: none;
			background: none;
			box-shadow: none;
		}

		border: none;
		font-size: $font-size * 1.5;

		i {
			@include animate-state;
		}
	}

	ul {
		display: none;

		li {
			display: none;
		}
	}

	&.flyout-open {
		button {
			i {
				@include animate-state;
				transform: rotate(-90deg);
			}
		}

		ul {
			@include depth;
			display: block;
			z-index: 1;
			margin: 0;
			padding: 0;
			list-style: none;
			position: absolute;
			top: $gutter;
			right: 0;
			border-radius: $border-radius;
			border: 1px solid $hairline-grey;
			background: $white;
			width: $gutter * 3;

			li {
				display: block;
				padding: $one-unit $one-unit/4;

				&:hover {
					background-color: $hairline-grey;
					color: $black;

					button {
						color: $black;
					}
				}

				button {
					cursor: pointer;
					font-size: $font-size * 0.9;
					color: $mutedBlack;
					text-align: right;
					width: 100%;
					background: none;
					border: none;
				}
			}
		}
	}
}
