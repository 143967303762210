.list-group-projects {
  .list-group-item.project {
    .tenancy-inline {
      text-align: center;
    }
    .tenant-inline {
      flex: 2;
    }
    .project-property {
      flex: 1;
      flex-direction: column-reverse;
      .tenancy-inline,
      .property-inline {
        flex: 1;
        text-align: left;
        margin: 0;
        width: auto;
        max-width: 100%;
        h3.tenancy_name {
          margin: 0;
        }
      }
    }
  }
}