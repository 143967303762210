.phase-ahead {
	.status {
		color: $green;
	}
	&.phase-at_risk {
		.status {
			color: $orange;
		}
	}
}

.phase-behind {
	.status {
		color: $red;
	}
}

.phase-closed {
	.status {
		color: $green !important;
	}
}

.project-behind {
	.phase-ahead {
		.status {
			color: $green;
		}
	}
}
