.application-layout {
	article.card {
		background: $white;
		border-radius: $border-radius;
		border: none;
		padding: 0;
		position: relative;

		&.protected {
			margin-bottom: $gutter/2;
			.card-block {
				border-radius: $border-radius;
				background-color: lighten($yellow, 30%);
				border: 1px solid lighten($yellow, 20%);
				padding: $one-unit $one-unit * 2 !important;
				display: flex;
				align-items: center;

				div {
					margin-left: $gutter/2;
					flex: 1;
					* {
						margin: 0;
					}
				}
			}
		}
		&.has-error {
			.btn.btn-primary {
				background-color: $red;
			}

			h2 {
				color: $red;
			}

			border: 1px solid $red;
		}

		&.is-dragging {
			&:before {
				font: normal normal normal 60px/1 FontAwesome;
				content: "\f093 ";
				position: absolute;
				top: 50%;
				left: 50%;
				color: white;
			}

			* {
				opacity: 0.5 !important;
			}

			background-color: $purple;
		}

		.fa-circle-notch {
			display: none;
		}
		.is-active.fa-circle-notch {
			@include rotate;
			display: block;
			opacity: 0.3;
		}

		// .fa-circle-notch {
		// 	@include rotate;
		// 	display: block;
		// 	opacity: 0.3;
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// }
	}

	&.is-dropping {
		background-color: $purple;
	}

	.btn-disclosure {
		position: absolute !important;
		top: 5px;
		right: 5px;
	}

	.view-hint {
		margin: $one-unit * 2;
		color: $accent-color2;
		font-weight: 200;

		* {
			margin: 0;
		}
	}

	// .help {
	// 	color: $grey;
	// 	font-weight: 200;
	// 	position: absolute;
	// 	bottom: $one-unit * 2;
	// 	right: $one-unit * 2;
	// 	cursor: pointer;
	// 	z-index: 9999;
	// 	opacity: 0.2;

	// 	.help-body {
	// 		@include depth;
	// 		@include animate-state("all", 100ms);
	// 		opacity: 0.0001;
	// 		transform: scale(0.0001);
	// 		transform-origin: bottom right;
	// 		color: $white;
	// 		background-color: $blue;
	// 		position: absolute;
	// 		bottom: 0;
	// 		right: 0;
	// 		left: auto;
	// 		min-width: 300px;
	// 		padding: $one-unit * 2;
	// 		border-radius: $one-unit/4;
	// 		z-index: 999999999;
	// 	}

	// 	&:hover {
	// 		color: $blue;
	// 		opacity: 1;

	// 		.help-body {
	// 			@include animate-state("all", 100ms);
	// 			transform-origin: bottom right;
	// 			opacity: 1;
	// 			transform: scale(1);
	// 		}
	// 	}
	// }

	.card-block {
		flex: 1 1 auto;
		position: relative;
		padding: $one-unit * 2;

		.card-header {
			@include reset;
			display: flex;
			margin-bottom: $one-unit;
			align-items: center;

			h2 {
				margin: 0;
			}

			.icon {
				width: $one-unit * 2;
				display: inline-block;
				margin-right: $one-unit;
			}

			.card-links {
				margin-left: $one-unit * 4;

				.card-actions {
					display: block;

					.btn {
						@include toolbar-button;
					}
				}
			}

			article {
				@include reset;
			}

			& + .view-hint {
				margin-top: round($gutter/-4);
			}

			.row {
				margin: 0;
			}

			.loader {
				margin: 0;

				&.loader-unauthorised,
				&.loader-unavailable {
					background-color: $orange;
					border-radius: $one-unit;
				}

				header {
					margin: 0;
					position: relative;
					background-color: transparent;

					p {
						margin: 0;
					}
				}

				h2 {
					text-transform: capitalize;
					margin-bottom: $one-unit;
				}

				.reload {
					position: absolute;
					top: 0;
					right: 0;
					size: 24px;
				}

				#view-mode {
					position: static;
					margin-bottom: $one-unit;
				}
			}
		}

		.card-block {
			padding: 0;

			article.card {
				margin-bottom: $one-unit * 2;

				.card-header {
					h2 {
						font-size: round(1.1 * $font-size);
					}
				}
			}
		}
	}

	&.alert {
		border: 1px solid $red;

		h2 {
			color: $red;

			&::before {
				font-family: FontAwesome;
				content: "\F06A ";
				color: $red;
			}
		}

		.view-hint {
			p {
				color: $red;
			}
		}
	}
}

@media only screen and (max-width: 414px) {
	.card .card-block > .btn {
		display: block;
	}

	aside .card .card-block .btn {
		position: static;
	}
}

@media only screen and (max-width: 1300px) {
	aside .card .card-block #view-mode {
		display: none;
	}
}
