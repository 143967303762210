div.workflow-graph {
	background-color: $white;
	padding: $gutter;
	border: 1px solid $hairline-grey;

	&.landscape {
		overflow-x: scroll;
		width:98vw;
		max-width: 98%;
	}

	svg {
		width: 100%;

		g.node {
			text {
				font-family: "Lato", helvetica, sans-serif !important;
				font-weight: 500;
				font-size: 12px;
			}

			&.task-closed {
				polygon,
				rect {
					fill: $green !important;
					stroke: $green !important;
				}

				text {
					fill: $white !important;
				}
			}
		}
	}
}
