.badge {
  background-color: $accent-color2;
  margin-right: 3px;
  color: $white;
  min-width: 50px;
  padding: 4px 10px;
  border-radius: $one-unit/4;
  -webkit-font-smoothing: antialiased;

  &.badge-danger {
    background-color: $red;
  }

  &.badge-success {
    background-color: $green;
  }

  &.badge-info {
    background-color: $blue;
  }
}
