/**** INSIGHTS AND REPORTS ****/

#insights-card {
	.card-header {
		display: none;
	}

	.card {
		background-color: $hairline-grey;
		padding: $one-unit * 2;
		border-radius: $border-radius;
		margin-bottom: $one-unit * 4;
		&.details {
			width: 30%;
		}
	}

	.tabs {
		ul.nav {
			margin: $one-unit * 2 0;

			li.nav-item {
				-ms-flex: auto;
				flex: auto;
				margin-left: $one-unit * 2;
				&:first-of-type {
					margin-left: 0;
				}
				a {
					padding: $one-unit * 2;
					text-align: center;
					border: 1px solid $blue;
					border-radius: $border-radius;
					color: $blue;

					&:hover {
						color: $white;
					}
					&.active {
						background-color: $blue;
						border: 1px solid $blue;
						color: $white;
					}
				}
			}
		}
		#lease-table-card {
			.report-header {
				display: flex;
				flex-direction: row;
				& > * {
					margin-right: $one-unit * 2;
				}
			}
		}
	}
}
