table.table {
  border-bottom: 1px solid darken($hairline-grey, 10%);
  margin-top: $one-unit;
  thead {
    tr {
      th {
        border-color: darken($hairline-grey, 10%);
        border-bottom-width: 1px;
        &.currency {
          text-align: right;
        }
      }
    }
  }

  .currency {
    text-align: right;
    font-family: $font-family-mono;
  }

  tbody {
    tr {
      td {
        border-color: $hairline-grey;
        vertical-align: middle;

        &.filtered {
          padding: 0;
          height: 100%;
          position: relative;
          @include animate-state;

          &:hover {
            background-color: $accent-color2;
            @include animate-state;

            a {
              color: $black;
              text-decoration: none;

              &::after {
                color: inherit;
                font: normal normal normal 14px/1 FontAwesome;
                content: "\f0b0 ";
                position: absolute;
                top: 16px;
                right: 0.75rem;
                opacity: 1;
                @include animate-state;
              }
            }
          }

          a {
            text-decoration: none;
            padding: 0.75rem;
            position: relative;
            display: inline-block;
            height: 100%;
            width: 100%;

            &::after {
              opacity: 0.01;
              @include animate-state;
            }
          }
        }
      }

      &:nth-of-type(odd) {
        background-color: darken($white, 2);
      }
    }
  }

  h2,
  h3,
  h4 {
    margin: 0;
  }

  td,
  th {
    width: auto;
    padding: $one-unit * 2;
    vertical-align: middle;
    text-align: left;
  }

  &.sortable {
    th {
      padding: 0;

      a.sort {
        padding: 10px;
        position: relative;
        display: block;
        transition: background-color 300ms ease-out;

        i {
          opacity: 0;
          position: absolute;
          top: 15px;
          right: 10px;
          color: rgba(39, 56, 69, 0.5);
          transition: background-color 300ms ease-out;
        }

        &:hover {
          cursor: s-resize;
          background-color: rgba(217, 227, 233, 0.2);
          transition: background-color 300ms ease-out;
          text-decoration: none;

          i {
            opacity: 1;
            transition: opacity 300ms ease-out;
          }
        }
      }
    }
  }

  .status {
    width: 24px;
    text-align: center;
  }

  .numeric {
    width: 120px;
    text-align: center;
  }

  .date {
    width: 150px;
    text-align: left;
  }

  .datetime {
    width: 200px;
    text-align: left;
  }

  .metric-kind {
    width: 60px;
    text-align: center;
  }

  .tags {
    width: 60px;
    text-align: center;
  }

  .address {
    width: 50%;
  }

  .financial {
    width: 120px;
    text-align: right;
  }

  .icon {
    text-align: center;
    width: 30px;
  }

  .tags {
    text-align: center;
    width: 60px;
  }

  .thumbnail {
    text-align: right;

    .document {
      margin: 3px;
      padding: 1px;
      float: right;
      border: 1px solid $accent-color2;
      width: 100px;
      height: 60px;

      &:hover {
        border: 1px solid darken($accent-color2, 30);
      }
    }
  }

  .financial {
    font-family: "Inconsolata", monospace;
  }

  .action {
    width: 90px;
    text-align: center;

    .btn {
      width: 90px;
    }
  }

  .report-name {
    width: auto;
  }

  .balance {
    text-align: right;
  }

  .report-download {
    width: 120px;
    text-align: center;
  }
  // td.status {
  //     display: block;
  //     width: 15px;
  //     height: 15px;
  // }
}

h2 + table {
  margin-top: 15px;
}

.table-view {
  position: relative;
}

.table-view #view-mode {
  position: absolute;
  right: 20px;
  top: 20px;
}

.table-view header #view-mode {
  position: absolute;
  right: 0;
  bottom: 0;
}

.muted {
  color: #b6c3cc;
}

.table-row-load-appear {
}

.table-row-load-appear tr {
  opacity: 0.01;
}

.table-row-load-appear.table-row-load-appear-active tr {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.table-row-load-appear tr:nth-child(1) {
  transition-delay: 100ms;
}

.table-row-load-appear tr:nth-child(2) {
  transition-delay: 200ms;
}

.table-row-load-appear tr:nth-child(3) {
  transition-delay: 300ms;
}

.table-row-load-appear tr:nth-child(4) {
  transition-delay: 400ms;
}

.table-row-load-appear tr:nth-child(5) {
  transition-delay: 500ms;
}

.table-row-load-appear tr:nth-child(6) {
  transition-delay: 600ms;
}

.table-row-load-appear tr:nth-child(7) {
  transition-delay: 700ms;
}

.table-row-load-appear tr:nth-child(8) {
  transition-delay: 800ms;
}

.table-row-load-appear tr:nth-child(9) {
  transition-delay: 900ms;
}

.table-row-load-appear tr:nth-child(10) {
  transition-delay: 1000ms;
}

.table-row-load-appear tr:nth-child(11) {
  transition-delay: 1100ms;
}

.table-row-load-appear tr:nth-child(12) {
  transition-delay: 1200ms;
}

.table-row-load-appear tr:nth-child(13) {
  transition-delay: 1300ms;
}

.table-row-load-appear tr:nth-child(14) {
  transition-delay: 1400ms;
}

.table-row-load-appear tr:nth-child(15) {
  transition-delay: 1500ms;
}

.table-row-load-appear tr:nth-child(16) {
  transition-delay: 1600ms;
}

.table-row-load-appear tr:nth-child(17) {
  transition-delay: 1700ms;
}

.table-row-load-appear tr:nth-child(18) {
  transition-delay: 1800ms;
}

.table-row-load-appear tr:nth-child(19) {
  transition-delay: 1900ms;
}

.table-row-load-appear tr:nth-child(20) {
  transition-delay: 2000ms;
}

.table-row-load-appear tr:nth-child(21) {
  transition-delay: 2100ms;
}

.table-row-load-appear tr:nth-child(22) {
  transition-delay: 2200ms;
}

.table-row-load-appear tr:nth-child(23) {
  transition-delay: 2300ms;
}

.table-row-load-appear tr:nth-child(24) {
  transition-delay: 2400ms;
}

.table-row-load-appear tr:nth-child(25) {
  transition-delay: 2500ms;
}

.table-row-load-appear tr:nth-child(26) {
  transition-delay: 2600ms;
}

.table-row-load-appear tr:nth-child(27) {
  transition-delay: 2700ms;
}

.table-row-load-appear tr:nth-child(28) {
  transition-delay: 2800ms;
}

.table-row-load-appear tr:nth-child(29) {
  transition-delay: 2900ms;
}

.table-row-load-appear tr:nth-child(30) {
  transition-delay: 3000ms;
}

.table-row-load-appear tr:nth-child(31) {
  transition-delay: 3100ms;
}

.table-row-load-leave tr {
  opacity: 1;
}

.table-row-load-leave.table-row-load-leave-active tr {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
@media only screen and (max-width: 425px) {
  table.table {
    tbody {
      border: none;
    }

    td,
    th {
      padding: 20px 10px;
    }
  }

  .table-view {
    #view-mode {
      position: absolute;
      right: 5px;
      top: 15px;
    }
  }
}
