div.error-boundary {
  margin: $one-unit * 2;
  padding: $gutter;
  border-radius: $border-radius;
  border: 5px solid $red;
  background-color: lighten($red, 30%);
  color: $red;

  h1 {
    font-weight: 600;
    line-height: 1;
  }

  p {
    font-weight: 600;
    margin: 0;
  }
}

#app.is-error {
  height: 100%;
  align-items: center;
  justify-content: center;

  section {
    article {
      text-align: center;

      p {
        margin: 0;
      }
    }
  }
}