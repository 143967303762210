// .task {
//   // @include task-status;
//   .task-title {
//     flex: 1;
//     h4 {
//       margin: 0;
//     }
//     h4 + h6 {
//       margin-top: 3px;
//       margin-bottom: 0;
//     }
//   }
// }

.application-layout article div ul.nav-tabs {
  margin-bottom: 25px;
}

.edit-task-modal {
  width: 90%;
  top: 5%;
  left: 5%;
}

.edit-task-modal-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.edit-task-modal-section .edit-task-modal-body ul.nav {
  width: 100%;
}

.edit-task-modal-section footer {
  align-items: flex-end;
}

.filter-row {
  position: absolute;
  top: -18px;
  right: 0;
  font-size: 0.7rem;
  color: hsla(205, 9%, 47%, 1);
}

.filter-row a {
  padding-left: 4px;
  color: hsla(205, 9%, 47%, 0.5);
}

@media only screen and (max-width: 425px) {
  table#tasks-table th {
    display: none;
  }

  table#tasks-table td {
    display: block;
    width: auto !important;
    border: none;
    padding: 3px;
  }

  table#tasks-table td.task-name {
    font-size: 1.2rem;
  }

  table#tasks-table td .status {
    display: inline;
  }

  table#tasks-table td:first-child {
    padding-top: 1rem;
  }

  table#tasks-table td:last-child {
    border-bottom: 1px solid hsla(205, 18%, 76%, 1);
    padding-bottom: 1rem;
  }

  table#tasks-table td.empty:last-child {
    border: none;
  }

  .application-layout article div ul.nav-tabs {
    margin-bottom: 0;
  }

  .condition-input {
    padding: 10px;
  }

  .condition-input .condition-body {
    max-width: 80%;
  }
}
