.react {
	display: block;
}
.react-me {
	font-family: Helvetica;
	cursor: pointer;
	display: block;
	position: relative;
}
.react-me .inner {
	position: absolute;
	bottom: 50%;
}
.react-me:hover .react-box {
	display: block;
}
.react-box {
	list-style-type: none;
	margin: 0;
	display: none;
	padding: 0 5px;
	border-radius: 150px;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.react-box ul {
	margin: 0 8px !important;
	padding: 0;
	display: flex;
}
.react-box li {
	display: inline-flex;
	position: relative;
	font-size: 30px;
	align-items: center;
	justify-content: center;
}
.react-box li:not(:last-child),
.react-box li:not(:first-child) {
	margin: 0 3px !important;
}
.react-box li:hover {
	transform: scale(1) translateY(-5px);
	transition: transform 200ms ease;
}
.react-box li:hover:before {
	display: inline-block;
}
.react-tooltip-lite {
	background: #333;
	color: white;
}

.react-tooltip-lite a {
	color: #86b0f4;
	text-decoration: none;
}

.react-tooltip-lite a:hover {
	color: #4286f4;
}

.react-tooltip-lite-arrow {
	border-color: #333;
}
.target {
	text-decoration: underline;
	cursor: pointer;
}

.target .react-tooltip-lite {
	cursor: default;
}
.comment-react-icon {
	> span {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 2px 0;
	}
}

.twemoji {
	width: 20px;
	height: 20px;
}
.twemoji2 {
	width: 30px;
	height: 30px;
}

.liEmoji {
	width: 30px;
	flex-shrink: 0;
	padding: 5px 0 !important;
}
.react-box {
	ul {
		gap: 5px;
	}
}
#app {
	article.comments {
		.metadata {
			align-items: flex-startco;
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: 0;
			border-radius: $border-radius;
			border: 1px solid $hairline-grey;
			padding: $one-unit;
			z-index: 99;

			.close {
				position: absolute;
				top: $one-unit;
				right: $one-unit * 2;
			}

			div {
				flex: 1;
				height: min-content;

				h6 {
					margin: 0;
				}

				h3 {
					font-weight: 500;
					margin: 0;

					a {
						color: $black;
					}
				}

				p {
					margin: 0;
					color: $mutedBlack;
				}

				a {
					color: $mutedBlack;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			img {
				width: $gutter * 10;
				height: auto;
				margin-right: $one-unit * 2;
			}

			iframe {
				margin-right: $one-unit * 2;
			}
		}

		.list-group-component-comments {
			.list-group-item {
				padding-right: $one-unit * 5;

				&.event-project_closed {
					background-color: $white;
					position: relative;
					height: $gutter;
					border: none;

					> span {
						position: absolute;
						left: $gutter * 2;
						display: inline-block;
						font-size: round($font-size * 0.8);
						color: $color-archived;
						background-color: $white;
						padding: 3px;
						z-index: 2;
					}

					hr {
						z-index: 1;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						border-bottom: 3px solid $color-archived;
					}
				}

				&.comment {
					align-items: flex-start;
					flex-wrap: wrap;
					position: relative;

					&.hightlight {
						background-color: #f2feff;
					}

					&:last-of-type {
						border-radius: 0;
						border-bottom: none;
					}

					.list-group-item-avatar {
						order: 1;
						width: $gutter * 1.5;
						margin-right: $one-unit;
						.avatar {
							div {
								width: $gutter !important;
								height: $gutter !important;
								line-height: $gutter !important;
								font-size: round($font-size * 0.8) !important;
							}
						}
					}

					.list-group-item-body {
						order: 2;
						flex: 1;
						flex-direction: column;
						padding: 0;

						.byline {
							> span {
								margin: 0 $one-unit;

								&:first-of-type {
									margin-left: 0;
								}

								&:last-of-type {
									margin-right: 0;
								}

								.formatted-date {
									.formatted-date-date,
									.formatted-date-time {
										font-size: inherit;
									}
								}

								a {
									color: $dark-blue;
								}
							}
						}

						.text-block {
							.text-block-attribution {
								font-weight: bold;
								margin-right: $one-unit/4;
							}

							.text-block-content {
								p {
									margin-bottom: $one-unit;
									font-size: round($font-size * 1.1);
								}
							}
						}
					}

					.flyout {
						position: absolute;
						top: 0;
						right: 0;

						.fa-angle-left {
							color: $mutedBlack;
						}
					}

					&.is-reply {
						padding-left: $gutter * 2;
					}
				}
			}
		}

		.new-comment-form {
			position: relative;

			button {
				height: $one-unit * 4;
			}

			button.btn-send {
				width: $gutter * 4;
				position: absolute;
				bottom: $one-unit * 4;
				right: $one-unit * 7;
			}

			button.btn-add {
				@include toolbar-button;

				i {
					margin: 0;
				}

				text-align: center;
				width: $one-unit * 4;
				position: absolute;
				bottom: $one-unit * 4;
				right: $one-unit;
			}

			.form-group {
				&.input-wysiwyg {
					margin-top: 3px;
				}

				label {
					display: none;
				}
			}
		}

		.comment {
			&-reply-link {
				font-size: 13px;
				display: inline-block;
				text-align: left;
				cursor: pointer;
			}

			&-add-react {
				font-size: 12px;
				color: #838383;
			}

			&-react-icon {
				font-size: 13px;
				border: 1px solid #ddd;
				padding: 1px 4px;
				border-radius: 5px;
				cursor: pointer;
			}

			&-timestamp {
				font-size: 10px;
				// position: absolute;
				// top: 0;
				// right: 0;
			}
		}
	}
}
