.ReactModalPortal {
	.ReactModal__Overlay {
		.ReactModal__Content {
			&.document-modal-image {
				max-height: 90%;

				section {
					overflow: auto;
					padding-top: 0;

					.preview {
						.document-preview {
							max-height: 100vh;
						}
						min-width: 50%;
						max-width: 80%;
					}
				}
			}
		}
	}
}
