.project {
	header#main-header {
		.main-header-metadata {
			span.formatted-date {
				font-weight: bold;
				margin-left: $one-unit;
			}
		}
	}
	.card-block {
		//TODO - this is so HACKY. I will fix this.
		//I couldn't turn off the sort/filter bar in this instance as it would BREAK THE LAYOUT.
		//So I had to MAKE EVERYTHING WHITE.
		.sort-filter-bar-container {
			color: transparent;
			border: transparent;
			dl {
				color: white;
				dt {
					color: transparent;
				}
				dd {
					color: transparent;
				}
			}
		}

		#tasks {
			.list-group {
				.list-group-section {
					.list-group-component {
						.list-group-item {
							.list-group-item-body {
								flex: none;
								width: $one-unit * 2;
								text-align: center;
								padding: 0;
								text-align: center;
								font-size: round($font-size * 0.9);
								position: relative;
								top: 2px;
								margin: $one-unit/4;
								border-radius: $border-radius;
								background: $mutedBlack;
								color: $white;
							}
						}
					}
				}
			}

			#conditions {
				.list-group {
					.list-group-section {
						.list-group-component {
							.list-group-item {
								padding: $one-unit * 2;
								align-items: normal;
								flex-direction: column;

								.list-group-item-heading {
									margin-bottom: $one-unit;
								}

								.list-group-item-body {
									.sort-filter-bar-container {
										display: none;
									}
									padding: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
