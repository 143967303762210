@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700|Open+Sans:400,600");
$one-unit: 8px;
$font-size: floor($one-unit * 1.8);
$gutter: $one-unit * 4;
$border-radius: 1;
$font-family-body: "Open Sans", Helvetica, sans-serif;
$font-family-header: Inter, Montserrat, Helvetica, sans-serif;
$font-family-mono: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
$night-bg-color: #10171e;
$white: #ffffff;
$night-white: lighten($night-bg-color, 70%);
$black: darken($white, 70%);
$hairline-grey: darken($white, 3%);
$mutedBlack: lighten($black, 50%);
$darkBlack: lighten($black, 20%);
$night-hairline-grey: lighten($night-bg-color, 30%);
$bg-color: darken($white, 6%); // 6% gray
$bg-color-alt: darken($white, 24%);
// 24% gray
$accent-color1: darken($white, 54%); // 54% gray
$accent-color2: darken($white, 30%); // 30% gray
$grey: hsla(0, 0%, 50%, 1);
$blue: #00c6d7;
$dark-blue: #00555d;
$yellow: lighten(#ffcc00, 18%);
$accent-yellow: #ffcc00;
$orange: hsl(31, 100%, 50%);
$accent-orange: darken($orange, 6%);
$green: #00af3d;
$accent-green: darken($green, 6%);
$purple: #ab47bc;
$neutral: $yellow;
$brown: #684f27;
$red: #fc4036;
$accent-red: darken($red, 6%);
$accent-blue: darken($blue, 6%);
$color-black-80:#486279;

$color-ahread: $orange;
$color-at_risk: $red;
$color-completed: $green;
$color-archived: $dark-blue;

$timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

//Feedback Colours from http://internal.tcpinpoint.com/2019/10/30/patterns/
$warning-dark: #a38226;
$warning-mid: #ffcb3b;
$warning-light: #ffdd82;
$info-light: #bac1f1;
$info-mid: #6573e1;
$info-dark: #2b378c;

@keyframes spin {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
