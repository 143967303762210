.tenant-inline {
    h4 {
        font-weight: 300;
    }

    h5 {
        margin: 3px 0 0;
        font-weight: 200;
    }
}

header {
    .tenant-inline {
        h4,
        h5 {
            margin: auto 2px;
            display: inline-block;
            font-size: inherit;
        }

        h5 {
            &::before {
                content: '(';
            }

            &::after {
                content: ')';
            }
        }
    }
}
