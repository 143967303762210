$size: 38px;

@mixin btn($color) {
    cursor: pointer;
    font-family: $font-family-header;
    background-color: $color;
    border: none;
    color: $white;
    border-radius: $border-radius;
    text-transform: capitalize;
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-size: $font-size * 0.9;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    line-height: 1;

    .text {
        p {
            margin: 0;
            line-height: 1;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: lighten(desaturate($color, 100), 40);
        border-color: lighten(desaturate($color, 100), 40);
        filter: blur(1px);

        &:hover {
            background-color: lighten(desaturate($color, 100), 40);
            border-color: lighten(desaturate($color, 100), 40);
            box-shadow: none;
        }
    }

    &.active.focus,
    &.active:focus,
    &.focus,
    &:active.focus,
    &:active:focus,
    &:focus {

        box-shadow: 0 1px 6px 4px rgba($black, 0.05);
        outline: none;
    }

    &:hover {

        box-shadow: 0 1px 6px 4px rgba($black, 0.05);
        background-color: darken($color, 10);
        border-color: darken($color, 10);
    }

    &:active {

        @include flat;
        transform: translateY(3px);
    }
}

@mixin btn-outline($color) {
    @include btn($color);
    border: 1px solid lighten($color, 20%);
    color: $color;
    background-color: $white;

    &:active,
    &:focus,
    &:hover {
        box-shadow: 0 $one-unit/4 $one-unit $one-unit/4 rgba($black, 0.05);
        background-color: $color;
        border-color: $color;
        color: $white;
    }
}

.btn {
    &.btn-primary {
        @include btn($blue);

        i {
            margin-right: $one-unit;
        }
    }

    &.btn-add {
        @include btn-outline($blue);
    }

    &.btn-success {
        @include btn($green);
    }

    &.btn-confirm {
        i {
            margin-right: 3px;
        }

        font-weight: 600;
        background-color: $orange !important;
        color: $white !important;
        border-color: $orange !important;
    }

    &.btn-trash {
        @include btn($red);
    }

    &.btn-danger {
        @include btn($red);
        max-width: $gutter * 4;
    }

    &.btn-info {
        @include btn($orange);
    }

    &.btn-outline-primary {
        @include btn-outline($blue);
    }

    &.btn-outline-info {
        @include btn-outline($orange);
    }

    &.btn-outline-danger {
        @include btn-outline($red);
    }

    &.btn-sm {
        min-width: $gutter * 3;
        padding: $one-unit $one-unit * 2;
        font-size: round($font-size * 0.8);
    }

    &.action {
        position: relative;
        top: -5px;
        font-size: $font-size !important;
        font-weight: bold;
        background-color: $green !important;
        color: $white !important;
        border-radius: $border-radius !important;
    }

    &.error {

        background-color: $red;
        border: 1px solid transparent;
        box-shadow: 0 0 0 0;

        span {

            opacity: 0.1;
        }

        svg {
            g.circle {
                circle {

                    opacity: 0.01;
                }

                path.spinner {

                    opacity: 0.01;
                    transform: scale(1);
                    fill: $white;
                }

                g.exclamation {

                    opacity: 1;
                    transform: scale(1);

                    ellipse,
                    polygon {
                        fill: $white;
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }

    &.loading {
        span {

            opacity: 0.1;
        }

        svg.circle {

            @include rotate;
            opacity: 1;
        }
    }

    svg {
        opacity: 1;
        transform: scale(1);
        margin-right: 3px;
    }

    svg.circle {

        opacity: 0.01;
        position: absolute;
        top: 50%;
        margin-top: $size / -2;
        left: 50%;
        margin-left: $size / -2;
        width: $size;
        height: $size;
        vertical-align: baseline;

        path,
        g {
            opacity: 0.00001;
        }

        path.spinner {
            opacity: 1;
            transform: scale(1);
            fill: $white;
        }
    }
}

dl.definition-list+button {
    margin-top: $one-unit;
}

.btn-outline-secondary.active.focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary:active.focus,
.btn-outline-secondary:active:focus,
.open>.btn-outline-secondary.dropdown-toggle.focus,
.open>.btn-outline-secondary.dropdown-toggle:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(19, 192, 215, 0.5);
}