.tenancy-inline {
  h4 {
    font-weight: 300;
  }

  h5 {
    margin: 3px 0 0;
    font-weight: 200;

    .tenancy-precinct {
    }

    .tenancy-division {
    }

    .tenancy-precinct + .tenancy-division {
      &::before {
        content: ' — ';
      }
      margin-left: 5px;
    }
  }
}

header {
  .tenancy-inline {
    h4,
    h5 {
      margin: auto 2px;
      display: inline-block;
      font-size: inherit;
    }

    h5 {
      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }
  }
}
