@mixin _selected {
    @include animate-state;
    color: $white;
    z-index: 2;
    background-color: $blue;
    border-color: $accent-blue;
    font-weight: 400;

    * {
        color: $white;
    }

    .fa-inverse {
        color: $blue;
    }

    // .badge {
    //   border-color: $blue;
    //   color: $blue;
    //   background: $white;
    // }

    select {
        background-color: white;
        color: $blue;
        border-color: white;

        &:focus {
            outline: none;
        }
    }

    button {
        background-color: $white;
        border-color: $blue;
        color: $blue;
    }
}

.list-group-item {
    border: 1px solid $hairline-grey;
    background: $white;

    &:first-child {
        border-radius: $border-radius $border-radius 0 0;
    }

    &:last-child {
        border-radius: 0 0 $border-radius $border-radius;
    }

    &:only-child {
        border-radius: $border-radius;
    }
}

.list-group-status {
    min-height: 74px;
    opacity:0.5;
    width: 100%;
    color: $mutedBlack;
    padding: $one-unit;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list-group {
    flex: 1;
    .is-active {
        .list-group-status {
            min-height: 74px;
            opacity:0.5;
        }
    }
    .list-group-section {
        display: flex;
        flex-direction: row;
        width: 100%;

        nav.grouped {
            opacity: 0.3;
            flex-direction: column;
            width: $gutter * 1.5;
            margin-right: $one-unit;
            text-align: center;

            &:hover {
                opacity: 1;
            }

            ul {
                margin-top: $one-unit;
                margin-bottom: $one-unit;
                display: flex;
                flex: 1;
                justify-content: space-between;

                li {
                    font-weight: 600;
                    border: none;
                    text-transform: uppercase;

                    a {
                        border: none;
                        color: $accent-color2;
                    }
                }
            }
        }

        .list-group-component {
            flex: 1;



            .alert {
                min-height: 74px;
                width: 100%;
                color: $mutedBlack;
                padding: $one-unit;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    margin-right: $one-unit;
                }
            }


            .list-group-item {

                padding: $one-unit * 2 $one-unit * 4 $one-unit *2 $one-unit *2;
                position: relative;
                border-color: $hairline-grey;
                color: $color-black-80;

                * {
                    &:only-child {
                        margin: 0;
                    }
                }

                &.event,
                &.deleted {
                    background-color: $hairline-grey;
                    border: $hairline-grey;
                    color: $mutedBlack;

                    .list-group-item-avatar,
                    .flyout,
                    .list-group-item-body {
                        display: none;
                    }

                    span {
                        margin: 0 1px;
                        flex: none;
                        font-size: $font-size;
                        opacity: 1;
                    }
                }

                &.selected {
                    @include _selected;
                }

                >.unread {
                    top: $one-unit/4;
                    right: $one-unit/4;
                }

                .list-group-item-avatar {
                    width: $gutter * 1.5;
                    margin-right: $one-unit * 2;
                    font-size: $font-size * 3;
                    color: $yellow;
                }

                .list-group-item-parent {

                    h3,
                    h4,
                    h5,
                    h6 {
                        line-height: 1.2;
                        margin: 0;
                    }
                }

                .list-group-item-heading {
                    overflow: hidden;
                    margin: auto 0;

                    // h3,
                    // h4,
                    // h5,
                    // h6 {
                    //     line-height: 1.2;
                    //     margin: 0;
                    // }

                    // h6 {
                    //     margin-bottom: 2px;
                    // }

                    flex: 1;
                    font-family: $font-family-body;
                }

                .list-group-item-statistics {
                    width: $gutter * 5;
                    display: flex;
                    order: 3;

                    .statistics-item {
                        p {
                            margin: 0;
                            padding: 0;
                        }

                        flex: 1;
                        justify-items: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .list-group-item-body {
                    position: relative;
                    order: 3;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding-right: $one-unit * 2;

                    h3,
                    h4,
                    h5 {
                        margin: 0;
                    }

                    h6 {
                        text-transform: uppercase;
                        font-weight: 200;
                        font-size: round($font-size * 0.8);
                    }

                    ul {
                        list-style: none;
                        margin: $one-unit;
                        padding: 0;

                        li {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                &:first-child {
                    border-radius: $border-radius $border-radius 0 0;
                }

                &:last-child {
                    border-radius: 0 0 $border-radius $border-radius;
                }

                &:only-of-type {
                    border-radius: $border-radius;
                }

                &:focus {
                    outline: none;
                }

                &.disabled {
                    opacity: 0.5;
                    background-color: $white;
                    color: $accent-color2;
                    cursor: not-allowed;
                }

                .badge {
                    order: 6;
                }
            }

            .status,
            .stamp {
                width: $gutter * 2;
                margin-right: $one-unit * 2;
                flex-direction: column;
                position: relative;
                display: flex;
                align-items: center;

                .badge {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    border: 1px solid $white;
                }

                // .fa-stack {
                //     font-size: $font-size * 3;
                //     height: $font-size * 3;
                //     line-height: $font-size * 3;
                //     width: $font-size * 3;

                //     i.fa-stack-2x {
                //         font-size: $font-size * 3;
                //     }

                //     i.fa-stack-1x {
                //         font-size: $font-size * 1.5;
                //     }
                // }


            }

            button.close {
                position: absolute;
                top: $one-unit !important;
                right: $one-unit * 2 !important;
            }

            &.list-group-item-nested {
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .list-group-item-heading {
                    padding: $one-unit;

                    button {
                        position: absolute;
                        right: 0;
                        border: none;
                        background: none;
                    }
                }

                .list-group {
                    width: 100%;

                    button.close {
                        position: absolute;
                        top: $one-unit * 1.5 !important;
                        right: $one-unit * 2 !important;
                    }

                    .list-group-component {
                        width: 100%;
                        flex: 1;


                        .list-group-item {
                            padding: $one-unit/4;

                            .list-group-item-heading {
                                padding: $one-unit/4;
                            }

                            .stamp {
                                margin-right: $one-unit/4;
                                width: $gutter;

                                i {
                                    font-size: $font-size;
                                }
                            }
                        }
                    }
                }

                .list-group-item-datapoint {
                    display: none;
                }
            }

            .inline {

                h3,
                h4,
                h5,
                h6 {
                    margin-bottom: 0;
                }
            }

            table {
                font-size: $font-size;

                tbody {
                    tr {
                        td {
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        a.list-group-item {

            &:hover,
            &:active,
            &:active:focus,
            &:active:hover,
            &.active,
            &.active:focus,
            &.active:hover {
                @include _selected;
            }

            &:hover {
                background-color: $blue;
            }

            .disclosure {
                position: absolute;
                height: 14px;
                top: 50%;
                right: $one-unit * 2;
                width: $gutter / 2;
                transform: translateY(-8px);
                text-align: right;
            }
        }
    }

    .list-group-add {
        margin-top: $one-unit;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;

        button {
            margin: 0 $one-unit;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &+h4 {
        margin-top: $one-unit * 2;
    }
}

.list-group+button {
    margin-top: $one-unit * 2;
}

.form-group {
    label {
        display: none;
    }

    .input-group {
        .form-control {
            border-radius: 0 0 $border-radius $border-radius;
        }
    }

    &.list-group-download {
        .disclosure {
            transform: rotate(0.25turn) translateY($one-unit);
        }
    }

    &+p {
        margin: $one-unit 0 0;
    }

    &+.btn {
        display: inline-block;
        margin-top: $one-unit;

        button {
            width: 100%;
        }
    }
}

aside {
    .list-group {
        .list-group-section {
            .list-group-component {
                max-height: $gutter * 8;
                overflow-y: auto;

                .list-group-item {
                    &:nth-child(even) {
                        background-color: darken($white, 2%);
                    }

                    &:hover {
                        &:nth-child(even) {
                            background-color: darken($blue, 2%);
                        }
                    }

                    align-items: center;
                    border-radius: 0;
                    border-right: none;
                    border-left: none;
                    padding: $one-unit 0;
                    position: relative;
                    display: flex;
                    flex-direction: row;

                    .stamp {
                        margin-right: $one-unit/4;
                        width: $gutter;

                        i {
                            font-size: $font-size;
                        }
                    }

                    .list-group-item-heading {
                        padding: 0;

                        h3 {
                            font-weight: 400;
                            font-size: round($font-size * 0.9);
                        }

                        h6 {
                            text-transform: capitalize;
                            font-size: round($font-size * 0.8);
                        }
                    }

                    .list-group-item-datapoint {
                        display: none;
                    }
                }
            }
        }
    }
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: hsla(0, 0%, 96%, 1);
}

/***/
.workspace {
    .list-group-component {
        .list-group-item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .stamp {
                order: 1;
            }

            >span {
                order: 2;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                flex-grow: 2;

                .list-group-item-parent {
                    order: 2;
                    flex-basis: 60% !important;
                }

                .list-group-item-heading {
                    order: 1;
                    flex-basis: 30% !important;
                }
            }

            .list-group-item-datapoints {
                order: 3;
                display: flex;
                flex-direction: row;
                margin-left: auto;

                .list-group-item-datapoint {
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    width: $gutter * 6;

                    a {
                        white-space: nowrap;
                    }

                    &.statistics {
                        margin-right: 3px;
                        color: $black;
                        min-width: 50px;
                        border-radius: $one-unit/4;
                        -webkit-font-smoothing: antialiased;

                        p {
                            margin: $one-unit/4;
                        }
                    }
                }

                >span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    .list-group-item-statistics {
                        display: flex;
                        flex-flow: row nowrap;
                    }

                    .schedule {
                        display: flex;
                        flex-direction: column !important;
                    }

                    .badge {
                        height: max-content;
                    }
                }
            }

            .disclosure {
                order: 4;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .list-group {
        .list-group-item {
            .list-group-item-datapoint {
                display: none;
            }
        }
    }
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .list-group a.list-group-item:hover {
    background-color: $blue;
}