@mixin form-control {
	@include animate-state;
	color: $black;
	flex-direction: row;
	font-size: $font-size;
	line-height: $font-size;
	padding: $one-unit;
	border-radius: $border-radius;
	margin: 0;
	border: none;
	position: relative;
	border: 1px solid $hairline-grey;
	background-color: transparent;
	background-image: none;
	text-align: left;
	z-index: unset;

	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: $hairline-grey;
		font-weight: 200;
	}

	&:focus,
	&:hover {
		@include animate-state;
		border-color: $blue;
		cursor: pointer;
		outline: none;
	}

	&select:not([size]):not([multiple]) {
		height: auto;
	}
}

fieldset {
	padding: $gutter / 4 0 0;
	margin: 0 0 $one-unit * 2 0;
	border: none;

	legend {
		width: auto;
		margin: 0;
		border: none;
		padding-right: $one-unit * 2;
		font-size: $font-size;
		font-weight: 500;
		font-family: $font-family-header;
		width: 100%;

		& + .empty-state,
		& + .alert {
			margin-top: $gutter/-2;
		}

		& + .alert {
			margin: 0 !important;
		}

		& + .alert + button {
			display: block;
			margin-top: $one-unit;
		}
	}

	&.disabled {
		opacity: 0.3;
	}
}

.pac-container {
	.pac-item {
		.pac-icon {
			display: none;
		}

		color: $mutedBlack;
		border-top: none;
		padding: $one-unit;
		font-size: $font-size;
		font-family: $font-family-body;

		.pac-item-query {
			.pac-matched {
				background-color: rgb(255, 255, 180);
			}

			font-size: $font-size;
			font-family: $font-family-body;
		}
	}
}

#app,
.ReactModalPortal {
	.form-group {
		margin-bottom: $one-unit * 2;
		position: relative;

		&.is-required {
			&:focus,
			&:hover {
				.form-control {
					@include animate-state;
					border-color: $red;
					outline: none;
				}
			}
		}

		&.is-fulfilled.is-required {
			.form-control {
				@include animate-state;
				outline: none;
			}

			&:focus,
			&:hover {
				.form-control {
					@include animate-state;
					outline: none;
				}
			}
		}

		&.has-prefix {
			.input-group-addon {
				border-radius: $border-radius 0 0 $border-radius;
			}

			.input-group {
				.required {
					left: $gutter;
				}

				.form-control {
					padding-left: 0;
					border-left: 0;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}

		&.has-suffix {
			.input-group-addon {
				border-radius: 0 $border-radius $border-radius 0;
			}

			.input-group {
				.form-control {
					padding-right: 0;
					border-right: 0;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}

		&.has-danger {
			label {
				@include animate-state;
				color: $red !important;
				font-weight: bold;
			}

			.btn-primary {
				@include animate-state;
				background-color: $red;
				border-color: $red;
			}

			.form-control {
				@include animate-state;
				color: $red;
				border-bottom: 1px solid $red;

				&::selection {
					@include animate-state;
					background-color: $red;
				}
			}

			.form-control-feedback {
				@include animate-state;
				color: $red;
				font-weight: 700;

				&::selection {
					background-color: $red;
				}
			}
		}

		&.large {
			.form-control {
				font-size: round($font-size * 1.5);
			}
		}

		.list-group {
			.list-group-section {
				.list-group-component {
					.list-group-item {
						padding: round($one-unit * 1.5);

						.list-group-item-heading {
							span {
								color: $mutedBlack;
							}
						}

						button.close {
							top: $one-unit/2 !important;
						}
					}
				}
			}
		}

		&.form-group-verification {
			.form-control {
				font-size: $gutter !important;
				padding: $one-unit * 2 !important;
				text-align: center !important;
				letter-spacing: $one-unit !important;
				font-family: monospace;
			}
		}

		&.form-group-color {
			.input-group {
				border-radius: $one-unit/4;
				background-color: $hairline-grey;
				height: $one-unit * 2;

				.form-control {
					display: none;
				}
			}
		}

		&.form-group-color {
			.input-group {
				display: flex;
				height: $gutter * 2;

				button {
					border-radius: $border-radius;
					border: none;
					flex: 1;
					height: $gutter * 2;
					border: 5px solid rgba(255, 255, 255, 1);

					&:hover {
						border: 5px solid rgba(255, 255, 255, 0.8);
					}

					&.active {
						border: 5px solid rgba(255, 255, 255, 0);
						color: $white;
						font-size: 30px;

						&::before {
							content: "‎✔";
						}
					}
				}
			}
		}

		&.form-group-checkbox {
			label {
				cursor: pointer;
				display: flex;

				span {
					order: 2;
				}

				.input-group {
					order: 1;

					.form-control {
						display: none;
					}

					width: 12px;
					margin: 0 $one-unit 0 0;
				}
			}
		}

		&.form-group-address {
			input {
				@include form-control;
				width: 100%;
				border-radius: 0 0 $border-radius $border-radius;
			}
		}

		&.form-group-money {
			.input-group {
				.form-control {
					text-align: right;
				}
			}
		}

		// &.form-control-color {
		//   input {
		//     padding: 0;
		//   }
		// }

		// &.form-control-checkbox {
		//   label {
		//     display: flex;

		//     span {
		//       margin-left: $one-unit;
		//       flex: 1;
		//       order: 2;
		//     }

		//     input {
		//       position: static;
		//       order: 1;
		//     }
		//   }

		//   .form-control-feedback {
		//     margin-top: 0;
		//   }
		// }

		.list-group.is-empty {
			.list-group-section {
				.list-group-component {
					.alert {
						border-radius: $border-radius $border-radius 0 0;
						border-bottom: 0;
						margin: 0;
					}
				}
			}
		}

		span.required {
			&.required-fulfilled {
				* {
					color: $green !important;
				}
			}

			* {
				color: $red;
			}

			padding: 0 $one-unit;
			line-height: 12px;
			bottom: -8px;
			background-color: $white;
			left: 4px;
			position: absolute;
			z-index: unset;

			span,
			svg,
			.fa,
			.fab,
			.fal,
			.far,
			.fas {
				font-size: 9px;
				margin-left: 2px;
			}
		}

		label {
			display: block;
			position: relative;
			margin-bottom: 0;
			color: $black;
			font-size: round($font-size * 0.9);
		}

		div.form-control-feedback {
			display: block;
			margin-top: 4px;
			font-size: round($font-size * 0.9);
			width: 100%;
			color: $darkBlack;
			margin-bottom: $one-unit/4;
		}

		div.input-group {
			textarea {
				line-height: 1.4;
				padding: $one-unit * 2;
			}

			.form-control {
				@include form-control;

				&.form-control-static {
					text-align: left;
					border: none;
					cursor: not-allowed;
					justify-content: flex-start;
				}
			}

			.input-group-addon {
				@include animate-state;
				background-color: transparent;
				font-weight: 100;
				font-size: round($font-size * 0.8);
				margin-top: 0;
				border-color: $hairline-grey;
				min-width: $gutter;
				padding: 0 $one-unit;

				&:last-of-type {
					padding-left: $one-unit/4;
					border-right: 1px solid $hairline-grey;
					border-radius: 0 $border-radius $border-radius 0 !important;
					text-align: right;
					border-left: none;
				}

				&:first-of-type {
					padding-right: $one-unit/4;
					border-left: 1px solid $hairline-grey;
					border-radius: $border-radius 0 0 $border-radius !important;
					text-align: center;
					border-right: none;
				}
			}
		}

		.form-control-child {
			.list-group {
				width: 100%;

				.list-group-item {
					border: 1px solid $hairline-grey;

					&:first-child {
						border-radius: 0 0 $one-unit/4 $one-unit/4;
					}
				}

				.alert {
					margin: 0;
					border-top: none;
					border-radius: 0 0 $one-unit/4 $one-unit/4;
				}
			}
		}

		.input-limit {
			&.over-input-limit {
				color: $red;
			}

			position: absolute;
			bottom: -20px;
			right: $one-unit;
			font-size: round($font-size * 0.8);
			color: $mutedBlack;
		}

		.form-control-checkboxes {
			.form-control-checkbox {
				margin: 0;
			}
		}

		.input-tags {
			display: block;
			height: $gutter;
			width: 100%;
			padding-top: 3px;

			.tag {
				cursor: pointer;
				border: 1px solid $accent-color2;
				color: darken($accent-color2, 10%);
				background-color: $white;
			}
		}
	}
}

ul.attributes {
	@include reset;
	margin-top: $one-unit/4;

	li {
		border: 1px solid $hairline-grey;
		margin-bottom: 3px;
		font-size: $font-size * 0.9;
		padding: $one-unit $one-unit * 2;
		border-radius: $border-radius;
		@include animate-state;

		&:hover {
			@include animate-state;
			border-color: $grey;
		}

		button {
			background: none;
			font-weight: bold;
			float: right;
			border: none;
		}
	}
}

.input-group-addon {
	margin-top: -4px;
}

.form-check-input {
	margin: 0;
}

.form-check-label {
	padding-left: 0;
	font-size: $font-size;
	font-weight: 200;
}
