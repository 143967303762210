.properties {
  .list-group {
    .list-group-item {
      .property-block {
        .property-name {
          margin: 0;
        }
        h6 {
          margin: 0;
        }
        flex: 1;
      }
    }
  }
}
