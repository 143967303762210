// .property-inline {
//   flex: 1;
//   max-width: 20%;
//   h4 {
//     font-weight: 300;
//   }

//   h5 {
//     margin: 3px 0 0;
//     font-weight: 200;
//   }
// }

header {
  .property-inline {
    h4,
    h5 {
      margin: auto 2px;
      display: inline-block;
      font-size: inherit;
    }
  }
}
