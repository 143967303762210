@mixin modal-component {
	@include reset;
	padding: $one-unit * 2 $gutter !important;
}

div.ReactModal__Body--open {
	overflow: hidden;
}

div.ReactModalPortal {
	div.ReactModal__Overlay {
		display: none !important;
		position: fixed !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		z-index: 100 !important;
		background-color: rgba($black, 0.8) !important;

		div.ReactModal__Content {
			@include animate-state;
			@include reset;
			@include modal-component;
			background-color: $white !important;
			position: absolute !important;
			top: 50% !important;
			left: 50% !important;

			max-width: 90%;
			min-width: 50%;
			max-height: 90% !important;
			border-radius: $border-radius;
			padding: 1px !important;
			margin-right: -50%;
			transform: translate(-50%, -50%);
			box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
			display: flex;
			flex-direction: column;
			-webkit-overflow-scrolling: "touch"; // overflow: visible !important;

			a {
				&:hover {
					background-color: transparent;
				}
			}

			button.close {
				position: absolute;
				top: $one-unit * 2;
				right: $one-unit * 2;
			}

			form {
				display: flex;
				flex-direction: column;
				height: 100%;
			}

			header {
				@include modal-component;
				border-bottom: 1px solid $hairline-grey;
				display: flex;
				flex-direction: row;
				align-items: center;
				min-height: $gutter * 2;

				.modal-header-avatar + .modal-header-body {
					margin-left: $one-unit;
				}

				.modal-header-body {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					h1,
					h2,
					h3,
					h4,
					h5 {
						text-transform: none;

						&:only-child,
						&:last-child {
							margin: 0;
						}
					}

					h4 {
						color: $white;
						font-size: round($font-size * 0.8);
					}

					p {
						color: $grey;
					}
				}
			}

			section {
				@include modal-component;
				padding-bottom: $gutter;
				flex: 1;
				overflow-y: auto;
				overflow-x: hidden;

				p {
					margin-bottom: $one-unit;
				}

				ul {
					@include reset;
					padding-bottom: $one-unit;

					li {
						margin-bottom: $one-unit/4;
					}
				}

				.input-wysiwyg {
					margin-bottom: 0;
				}

				label {
					width: 100%;

					.required {
						span {
							margin-right: 3px;
						}
					}
				}
			}

			.loading {
				padding: $gutter;

				p {
					display: none;
				}

				svg {
					g#border {
						path {
							fill: $hairline-grey;
						}
					}
				}
			}

			footer {
				@include modal-component;
				min-height: ($one-unit/4) + ($gutter * 2);
				border-top: 1px solid $hairline-grey;
				padding-top: $one-unit;
				display: flex;
				flex-direction: row-reverse;
				// justify-content: space-between;
				opacity: 1;

				button {
					display: inline-block;
					margin-left: $one-unit;
				}
			}

			&.success {
				.close {
					color: $white;
					box-shadow: none;
				}

				header {
					background-color: $green;
					border-bottom: 1px solid $green;
					color: $white;

					* {
						color: $white;
					}
				}

				footer {
					.btn {
						background-color: $green;
						border-color: $green;
					}
				}
			}

			&.alert {
				.close {
					color: $white;
					box-shadow: none;
				}

				header {
					background-color: $red;
					border-bottom: 1px solid $red;
					color: $white;

					* {
						color: $white;
					}
				}

				footer {
					.btn {
						background-color: $red;
						border-color: $red;
					}
				}
			}

			&.warning {
				.close {
					color: $white;
					box-shadow: none;
				}

				header {
					background-color: $orange;
					border-bottom: 1px solid $orange;
					color: $white;

					* {
						color: $white;
					}
				}

				footer {
					.btn {
						background-color: $orange;
						border-color: $orange;
					}
				}
			}

			&.tenant-modal {
				width: 50%;
			}
		}
	}

	div.ReactModal__Overlay--after-open {
		display: block !important;
	}
}

@media only screen and (max-width: 425px) {
	div.ReactModalPortal {
		div.ReactModal__Overlay {
			div.ReactModal__Content {
				padding: 2.5% !important;
				top: 3% !important;
				transform: initial !important;
				margin: 0 !important;
				left: 5% !important;
				right: 5% !important;

				&.task-modal {
					overflow: scroll;
					max-height: 99%;
					height: auto;
					display: block;
					flex-direction: column;

					.status {
						width: 100%;
						margin-bottom: 1rem;
						flex: 1;
					}

					.task-comments {
						border: none;
						border-top: 1px solid $accent-color2;
						padding: 1rem 0 0;
						flex: 1;
						width: 100%;
					}
				}
			}
		}
	}
}
