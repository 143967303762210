@mixin tag {
  @include animate-state;
  background-color: $white;
  border: 1px solid $hairline-grey;
  margin-right: $one-unit/4;
  color: $mutedBlack;
  min-width: $gutter;
  padding: 1px $one-unit;
  display: inline-block;
  border-radius: $border-radius;
  font-size: round($font-size * 0.7);
}

.tag {
  @include tag;
}

div.ReactTags__tags {
  position: relative;

  div.ReactTags__tagInput {
    input {
      @include animate-state;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $accent-color2;
      border-radius: 0;
      display: inline-block;
      margin: 0;
      padding: 5px 0;
      position: relative;
      width: 200px;
      outline: none;
      margin-right: 5px;

      &:hover {
        @include animate-state;
        border-color: $grey;
        outline: none;
      }

      &:focus {
        @include animate-state;
        border-color: $blue;
        outline: none;
      }
    }
  }

  div.ReactTags__selected {
    span.ReactTags__tag {
      @include tag;

      a.ReactTags__remove {
        &:hover {
          background-color: $accent-color2;
          color: $blue;
        }

        color: $white;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    div.ReactTags__suggestions {
      position: absolute;

      ul {
        list-style-type: none;
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
        background: $white;
        width: 200px;
        margin: 0;
        padding: 0;

        li {
          padding: 5px 10px;
          margin: 0;
          font-size: $font-size * 0.9;
          color: $grey;

          mark {
            background: none;
            padding: 0;
            font-weight: bold;
            color: $black;
          }

          &.active,
          &:hover {
            mark {
              color: $white;
            }

            background: $blue;
            color: $white;
            cursor: pointer;
          }
        }
      }
    }
  }
}