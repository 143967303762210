#workflows-card {
	position: relative;

	div.workflow-status {
		position: relative;
		display: flex;
		flex-direction: row;
		margin: $gutter 0 $gutter;
		justify-content: space-evenly;
		.phase {
			text-align: center;
		}
		a {
			flex: 1;
			width: 60px;
			height: $gutter * 2;
			text-align: center;
			position: relative;

			.fa-stack {
				.icon-figure {
					top: 0;
					left: 0;
					position: absolute;
				}

				height: 42px;
				width: 42px;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				position: absolute;
			}

			.deadline-label {
				position: absolute;
				top: -$gutter;
				left: 50%;
				transform: translate(-50%);
				display: block;

				i {
					font-size: $font-size * 1.3 !important;
				}
			}

			hr {
				margin: 0;
				padding: 0;
				border: 1px dotted $bg-color;
				position: absolute;
				top: 21px;
				z-index: -1;
				left: 0;
				right: 0;
			}

			svg {
				z-index: 2;
			}

			&:first-of-type {
				hr {
					left: 50%;
				}
			}

			&:last-of-type {
				hr {
					right: 50%;
				}
			}

			i {
				font-size: $font-size * 3;
			}

			figcaption {
				font-size: round($font-size * 0.8);
				text-transform: capitalize;
				color: $black;
				bottom: 0;
				left: 0;
				right: 0;
				text-align: center;
			}

			&.at_risk {
				color: $orange;
			}

			&.ahead {
				color: $yellow;
			}

			&.complete {
				color: $green;
			}

			&.behind {
				color: $red;
			}
		}

		figcaption {
			font-size: round($font-size * 0.8);
			text-transform: capitalize;
			color: $black;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
		}
	}

	.viewby-cards {
		.list-group-component-tasks {
			.list-group-section {
				.list-group-tasks {
					display: flex;
					flex-flow: row wrap;

					.list-group-item {
						display: flex;
						flex-basis: 30%;
						margin: $gutter $gutter 0 0;
						justify-content: space-between;

						.status {
							order: 2;
							margin-right: 0;
							width: auto;

							i {
								font-size: 2em;
							}
						}

						.list-group-item-body {
							order: 1;
							flex-basis: 70%;

							h6 {
								margin-top: $one-unit;
							}
						}

						.statistics-group {
							display: flex;
							flex-flow: row nowrap;
							order: 4;
							align-self: flex-end;

							.statistics-item {
								align-self: flex-end;
								padding-left: 0;

								p {
									margin-bottom: 0;
								}
							}
						}

						.closed-by,
						.due-at {
							order: 3;
							align-items: flex-start;
							align-self: flex-end;
							justify-content: flex-start;
							flex-basis: 100%;
							font-weight: 200;
							font-size: round($font-size * 0.8);
							font-family: $font-family-header;
							padding: $one-unit 0;
						}

						.closed-by {
							.formatted-date {
								display: none;
							}
						}

						.due-at {
							h6 {
								display: none;
							}
						}

						.badge {
							order: 5;
							align-self: flex-end;
							background-color: $mutedBlack !important;
						}

						.disclosure {
							order: 999;
							display: none;
						}

						svg.circle {
							display: none;
						}

						h4 {
							font-weight: 600;
							margin-bottom: $one-unit/2;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 425px) {
	div.workflow-status {
		display: none;
	}
}
