.breadcrumbs {
	color: $mutedBlack;
	font-size: round(0.8 * $font-size);
	padding: $one-unit $one-unit * 2;

	a {
		color: $mutedBlack;
	}

	p {
		margin: 0;
		line-height: inherit;
	}

	.greeting {
		color: black;
		float: right;
		text-align: right;

		span {
			margin-right: $one-unit;
		}
	}

	.breadcrumbs-items {
		ol {
			padding: 0;
			margin-bottom: 0;
			list-style: none;
			background-color: transparent;
			border-radius: 0;
			color: #849cad;

			.breadcrumb-item {
				a {
					color: #849cad;
				}
				&:first-child {
					&::before {
						content: "";
					}
				}

				&::before {
					display: inline-block;
					content: " / ";
					color: #849cad;
				}
			}
		}
	}
}
