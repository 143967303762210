.dependency-modal {
  width: 50%;

  .phase {
    .list-group {
      margin-bottom: 1rem;

      .list-group-item {
        position: relative;
      }
      li.list-group-item {
        opacity: 0.2;
      }
      a.list-group-item {
        &:hover {
          background-color: $blue;
        }
      }
    }
  }
}
