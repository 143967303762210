.add-task-widget {
  svg {
    @include animate-state;
    position: absolute;
    bottom: -10px;
    left: 0;
    opacity: 0.001;
    transform: scale(0.001);
    cursor: pointer;

    path {
      fill: $blue;
    }

    polygon {
      fill: $white;
    }
  }
}

tr.task-row {
  td.status {
    position: relative;
  }

  td {
    &:hover {
      .add-task-widget {
        svg {
          @include animate-state;
          left: -15px;
          opacity: 1.0;
          transform: scale(2);
        }
      }
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay {
    .ReactModal__Content.tasks-add-modal {
      width: 80%;

      header {
        position: relative;
        padding-bottom: 0;

        h4 {
          position: static;
          margin: 0;
        }

        h2 {
          margin: 0;
          font-weight: 100;
        }

        h1 {
          opacity: 0.2;

          &.has-name {
            opacity: 1;
          }
        }

        span {
          margin-right: $one-unit;
        }

        button {
          span {
            margin: 0;
          }
        }
      }

      .description {
        font-weight: 200;
      }
    }
  }
}
