.gu-mirror {
  @include animate-state;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  transform: scale(1.1);
}

.gu-hide {
  @include animate-state;
  display: none !important;
}

.gu-unselectable {
  user-select: none !important;
}

.gu-transit {
  @include animate-state;
  opacity: 0.2;
}

.reorder {
  opacity: 0.3;
  font-weight: 100;
  margin-right: $one-unit;
  cursor: grab;
}
