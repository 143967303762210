.application-layout {
	.tabs {
		ul.nav {
			margin: 0;

			li.nav-item {
				position: relative;

				> i {
					opacity: 0.5;
					position: absolute;
					top: 4px;
					right: 4px;
				}

				&.highlighted-tab {
					a.nav-link {
						background-color: darken($white, 10%);
					}
				}

				a.nav-link {
					padding: $one-unit $one-unit * 2;
					border-radius: $border-radius $border-radius 0 0;
					font-family: $font-family-header;
					color: $accent-color1;
					text-align: left;
					text-transform: capitalize;
					background-color: darken($white, 3%);

					i {
						margin-right: 3px;
					}

					&.active {
						background-color: $white;

						color: $black;

						&:hover {
							background-color: $white;

							color: $black;
						}
					}

					&:hover {
						background-color: $blue;

						color: $white;
					}
				}

				&:first-of-type {
					a.nav-link {
						border-top-left-radius: $border-radius;
					}
				}

				&:last-of-type {
					a.nav-link {
						border-top-right-radius: $border-radius;
					}
				}
			}
		}
	}
}
