#notifications-workspace {
	margin-top: 0;
	article.card {
		background-color: transparent;
		.card-block {
			padding: 0;
			.card-header {
				background-color: white;
				padding: $one-unit * 2;
				margin-bottom: $one-unit * 2;
				border-radius: $border-radius;
			}
			#activities {
				background-color: white;
				padding: $one-unit * 2;
				border-radius: $border-radius;
			}
		}
	}
}

#activities .activity-item-link .activity-item-relationship {
	text-transform: capitalize;
}