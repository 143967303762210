dl.definition-list {
	margin-bottom: $one-unit/2;

	div.definition-item {
		padding: 0;
		display: flex;
		margin-bottom: $one-unit/2;
		flex-direction: column;

		&:last-child {
			margin-bottom: 0;

			dd.definition-description {
				margin-bottom: 0;
			}
		}

		div.icon {
			width: $gutter;
		}

		div.definition-item-body {
			flex: 1;

			&:last-child {
				margin-bottom: 0;

				dd.definition-description {
					margin-bottom: 0;
				}
			}

			dt.definition-term {
				font-family: $font-family-header;
				color: $grey;
				font-size: round($font-size * 0.9);
				font-weight: 200;
				line-height: 1.2;
			}

			dd.definiiton-description-cursor {
				cursor: help;
			}

			dd.definition-description {
				font-family: $font-family-body;
				color: $black;
				font-weight: 200;
				line-height: 1.2;

				address,
				p {
					margin: 0;
				}

				ul {
					list-style: none;
					padding: 0;
					margin: 0;

					li {
						padding: 0;
						margin: 0;
					}
				}

				small {
					&:first-child {
						margin-right: $one-unit/4;
					}

					&:last-child {
						margin-left: $one-unit/4;
					}
				}

				.text {
					display: inline-block;
				}
			}
		}
	}
}
