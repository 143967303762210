.project-ahead {
	.status {
		color: $green;
	}
}

.project-at_risk {
	.status {
		color: $orange;
	}
}

.project-behind {
	.status {
		color: $red;
	}
}

.project-closed {
	.status {
		color: $dark-blue !important;
	}
}
