.property-location-views {
	article:first-child {
		margin-bottom: 0;
		border-bottom: none;
	}
	article:not(:last-child):not(:first-child) {
		border-top: none;
		border-bottom: none;
	}
	article:last-child {
		border-top: none;
		z-index: 99;
	}
}

.application-layout .property {
	.property-administrator {
		font-weight: bold;
		color: $green;
	}
}
