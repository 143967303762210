#app {
	> footer {
		@include reset;
		font-size: 0.7 * $font-size;
		ul {
			@include reset;
			display: flex;
			flex-direction: row;
			justify-content: center;
			li {
				@include reset;
				margin: 0 3px;
				.badge {
					position: relative;
					top: -3px;
				}
			}
		}
	}
	article.view {
		footer {
			display: flex;
			.btn {
				width: 100%;
				height: 100%;
				&:only-child {
					width: 50%;
					height: $gutter;
				}
			}
		}
	}
}

.application-layout {
	footer {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		align-content: flex-end;
		ul {
			margin-top: $one-unit * 2;
			height: $font-size;
			li {
				font-size: $one-unit * 1.6;
				.badge {
					background-color: $grey;
					color: $white;
				}
			}
		}
	}
}
.session-layout {
	footer {
		ul {
			li {
				.badge {
					background-color: $white;
					color: $blue;
				}
			}
		}
	}
}
