.application-layout.templates {
	article#phases-card {
		article.phase {
			.card-block {
				.card-links {
					// right: 0;
					// top: 0;
					position: absolute;
					margin-top: 30px;
					margin-right: 20px;
				}
				button.btn-add {
					top: 72px;
				}
				p.description {
					max-width: 80%;
					min-height: 22px;
				}
			}
		}
	}
}
