@mixin document-tool {
	border: 1px solid $blue;
	background: $blue;
	color: $white;
}
.upload-status {
	.progress {
		margin: $gutter/ 4;
		border-radius: $gutter/ 4;
		background-color: rgba($white, 0.6);

		.progress-bar {
			border-radius: $gutter/ 4;
			width: 70%;
			margin: 3px;
			background-color: $white;
		}
	}

	.document-uploading-size {
		font-size: $font-size * 0.9;
		display: block;
	}

	.document-uploading-estimated-seconds {
		font-size: $font-size * 0.8;
		display: block;
	}
}
// article#documents-list {
//   .list-group-component {
//     display: flex;
//     flex-direction: row;

//     #documents-list-documents {
//       flex: 1;

//       article.header {
//         .card-block {
//           header {
//             padding: $one-unit * 2;
//           }
//         }
//       }
//     }
//   }
// }

.document-list-group {
	margin-bottom: $one-unit;

	.card-links {
		top: $one-unit;
		right: $one-unit;
	}

	padding: 0;

	p.small {
		text-align: right;
		margin: 0;
	}

	.loader {
		margin: 0 auto;
		width: $gutter * 3;
		height: $gutter * 3;

		svg {
			width: $gutter * 3;
			height: $gutter * 3;
		}
	}

	#view-mode {
		position: static;
		left: 0;
		top: 0;
	}

	.list-group-item {
		text-decoration: none;
		overflow: hidden;
		background-color: $white;
		position: relative;

		&.uploading {
			@include animate-state;
			opacity: 0.5;
			background-color: $red;
		}

		&:active,
		&:focus {
			outline: none;
		}

		&.active {
			@include depth;
			background-color: $blue;
			color: $white;
			text-decoration: none;

			* {
				color: $white;
			}
		}

		&:hover {
			background-color: $blue !important;
			border-color: $accent-blue;
			color: $white;
			text-decoration: none;

			* {
				color: $white;
			}

			.document-view-icon {
				opacity: 0.5;
			}
		}

		&.document-expired {
			color: $white;
			border: 1px solid $accent-red;
			background-color: $red;

			* {
				color: $white;
			}

			.document-name {
				span.badge {
					line-height: 1;
					border: none;
					background: $white;
					color: $red;

					* {
						color: $red;
					}

					span {
						display: block;
					}
				}
			}

			&:hover {
				.document-name {
					span.badge {
						border: none;
						color: $blue;
						background-color: $white;

						* {
							color: $blue;
						}
					}
				}
			}
		}

		&.document-deleted {
			opacity: 0.5;
			color: $grey;
			border: 1px solid $grey;
			background-color: transparent;

			* {
				color: $grey;
			}

			span.badge {
				border: 1px solid $grey;
				background: transparent;
				color: $grey;

				* {
					color: $grey;
				}
			}

			&:hover {
				color: $white;

				span.badge {
					border-color: darken($blue, 10);
					background-color: $blue;
					color: $white;

					* {
						color: $white;
					}
				}
			}
		}

		.document-preview {
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}

		.document-icon {
			margin-right: $one-unit/4;
		}

		.document-name {
			flex: 1;
			line-height: inherit;
			font-size: inherit;
			margin: 0;
			text-transform: none;
			flex-direction: row;
			display: flex;
			align-items: center;

			h3 {
				flex: 1;
				line-height: inherit;
				font-size: inherit;
				margin: 0;
				text-transform: none;
				overflow: hidden;
			}

			p {
				font-weight: 200;
				max-width: 100%;
				line-height: inherit;
				font-size: inherit;
				margin: 0;
				text-transform: none;
			}

			span.badge {
				display: inline-block;
				line-height: inherit;
				font-size: inherit;
				margin: 0 5px;
				text-transform: none;

				span {
					display: inline;
					margin: 0;
				}
			}
		}
	}

	&.documents-grid-view,
	&.documents-list-view {
		.list-group-section {
			.list-group-component {
				.list-group-item {
					flex-basis: $gutter * 5;
					min-height: $gutter * 5;
					&.has-thumbnail {
						border: none !important;
						&:hover {
							border: none !important;
							.thumbnail {
								@include animate-state;
								position: absolute;
								transform-origin: center center;
								transform: scale(1.2);
								position: absolute;
								top: 0px;
								right: 0px;
								left: 0px;
								bottom: 0px;
							}
						}
						.thumbnail {
							@include animate-state;
							transform-origin: center center;
							position: absolute;
							top: 0px;
							right: 0px;
							left: 0px;
							bottom: 0px;
							background-position: center;
							background-size: cover;
						}
					}

					.list-group-item-heading {
						h3 {
							font-size: $font-size;
							margin-bottom: $one-unit/4;
						}

						h6 {
							font-size: round($font-size * 0.8);
						}
					}

					.formatted-date {
						text-align: left;
					}
				}
			}
		}
	}

	&.documents-grid-view {
		.list-group-section {
			.list-group-component-documents {
				display: flex;
				flex-flow: row wrap;

				.list-group-item {
					// min-width: 4 * $gutter;
					border-radius: $border-radius;
					flex: 1 1 6 * $gutter;
					display: flex;
					position: relative;
					flex-direction: column;
					justify-content: center;
					align-content: center;
					text-align: center;
					padding: $one-unit * 2;
					margin: 1px;
					border: 1px solid $hairline-grey;

					.stamp {
						margin: 0 0 $one-unit;
					}

					.disclosure {
						display: none;
					}

					&.document-deleted {
						display: none;
					}

					.list-group-item-content {
						flex-direction: column;
					}
					.list-group-item-datapoints {
						margin-left: 0;
					}

					&.document-uploading {
						background-color: $orange;
						color: $white;
						text-align: center;

						&:hover {
							background-color: darken($orange, 5) !important;
						}

						.stamp {
							opacity: 0.2;
						}

						.list-group-item-heading {
							opacity: 0.2;
						}

						.list-group-item-body {
							padding: 0;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					/* one item */
					a:first-child:nth-last-child(1),
					a:first-child:nth-last-child(2),
					a:first-child:nth-last-child(2) ~ a,
					a:first-child:nth-last-child(3),
					a:first-child:nth-last-child(3) ~ a,
					a:first-child:nth-last-child(4),
					a:first-child:nth-last-child(4) ~ a {
						flex: 1 1 100%;
					}

					.document-name {
						display: block;
						flex: none;
						font-size: round($font-size * 0.9);
					}

					.document-icon {
						margin: 3px auto;
						font-size: round($font-size * 1.5);
					}

					.formatted-date {
						font-size: $font-size * 0.7;
					}

					.document-filesize-bytes {
						font-size: $font-size * 0.6;
					}

					.user {
						font-size: round($font-size * 0.8);
					}

					.document-preview {
						@include animate-state;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: transparent;
						background-size: cover;
					}

					&:hover {
						.document-preview {
							@include animate-state;
							transform: scale(1.1);
						}
					}
				}
			}
		}
	}

	margin-bottom: $one-unit * 2;

	h2 {
		margin-top: 5px;
		margin-bottom: 0;
		flex: 1;
	}
}

.documents-tool {
	margin-top: $one-unit/4;
	display: flex;
	flex-direction: row;

	&:empty {
		border: none;
		padding: 0;
	}

	margin-left: 0;

	.documents-link {
		&:first-child {
			margin: 0;
		}

		@include document-tool;
		display: block;
		margin-left: auto;
	}

	.documents-upload {
		&:first-child {
			margin: 0;
		}

		label {
			margin: 0;
		}

		margin: 0 $one-unit;

		.btn {
			@include document-tool;
		}
	}

	.documents-download {
		&:first-child {
			margin: 0;
		}

		margin: 0 $one-unit;

		.btn {
			@include document-tool;
		}
	}
}

// .status-closed {
//   .documents {
//     position: relative;
//     padding-bottom: $gutter;

//     .document-items {
//       margin: 0 $one-unit * 2 $gutter;

//       .document-item {
//         flex: 1 1 20%;
//       }
//     }
//   }
// }

@mixin mobile-layout {
}

/* ----------- iPhone 4 and 4S ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	@include mobile-layout;
}

/* ----------- iPhone 5 and 5S ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	@include mobile-layout;
}

/* ----------- iPhone 6 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	@include mobile-layout;
}

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
	@include mobile-layout;
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
	@include mobile-layout;
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
	@include mobile-layout;
}
