#app {
	#list-group-component-conditions {
		.list-group-item.condition {
			.status {
				align-self: flex-start;
			}
			.condition-block {
				> * {
					margin: 0;
					width: max-content;
				}

				.descriptors {
					margin-top: $one-unit;
					> * {
						margin: 0;
					}
					.text-block {
						color: $mutedBlack;
						margin-top: $one-unit;
					}
				}
				.condition-actions {
					> * {
						margin: 0;
						margin-top: $one-unit * 2;
						margin-left: $one-unit * 2;
						height: max-content;
					}
					> :first-child {
						margin-left: 0;
						text-transform: capitalize;
					}
					display: flex;
					flex-direction: row;
					align-items: center;
					#form-group-response {
						label {
							display: flex;
							flex-direction: row;
							align-items: center;
							> * {
								margin-left: $one-unit * 2;
							}

							> :first-child {
								margin-left: 0;
							}
						}
					}
				}
				#documents-card {
					margin-top: $one-unit * 2;
					.card-block {
						padding: 0;

						#list-group-component-documents {
							margin-top: $one-unit;
						}
					}
				}
				.dismissed-meta {
					display: flex;
					flex-direction: row;
					> * {
						margin-top: $one-unit * 2;
						margin-left: $one-unit * 2;
					}

					> :first-child {
						margin-left: 0;
					}
				}
				.condition-meta {
					margin-top: $one-unit * 2;
					font-size: $font-size;
				}
			}
		}

		.condition-toolbar {
			margin-left: auto;
			align-self: flex-start;
			button {
				@include toolbar-button;
				i {
					margin-right: $one-unit;
				}
			}
		}
	}
}
