html {
	body {
		#app {
			#wizard {
				display: grid;
				flex-direction: column;
				grid-template-columns: auto;
				grid-template-rows: round($gutter * 3) min-content round($gutter * 1.2);
				grid-template-areas: "wizard-nav" "current-step" "wizard-footer";
				grid-row-gap: $one-unit * 2;

				nav {
					grid-area: wizard-nav;
					margin: 0;
					display: flex;
					align-content: center;
					justify-content: center;
					position: relative;

					ul {
						z-index: 2;
						margin: 0;
						padding: 0;
						display: flex;
						align-items: center;
						justify-items: center;

						li.step {
							position: relative;
							text-align: center;
							list-style: none;
							margin: 0 $one-unit/4;
							padding: 0;
							color: $bg-color;
							width: $gutter * 2;
							height: $gutter * 1.5;

							.step-number {
								position: absolute;
								width: $gutter * 2;

								height: $gutter;
								top: 0;
								left: 0;
							}

							.step-index {
								position: absolute;
								top: 4px;
								left: 0;
								width: $gutter * 2;
								text-align: center;
								color: white;
								font-weight: bold;
							}

							.step-label {
								position: absolute;
								bottom: 0;
								left: 0;
								width: $gutter * 2;
								text-align: center;
								font-size: $font-size * 0.8;
							}

							&.step-enabled {
								color: $bg-color-alt;
								cursor: pointer;
							}

							&.step-completed {
								color: $green !important;

								span.step-index {
									display: none;
								}
							}

							&.step-active {
								color: $blue !important;
							}
						}
					}
				}

				> section {
					grid-area: current-step;
					background-color: $white;

					h1 {
						line-height: 1;
					}

					.step {
						article.card {
							margin: 0;
							box-shadow: none;

							.card-block {
								.card-header {
									justify-content: center;
									margin-bottom: $one-unit * 2;
								}
								.row {
									justify-content: center;
								}
								> button.btn {
									float: right;
								}

								button.close {
									z-index: 99;
									position: absolute;
									top: 0;
									right: 0;
								}

								button.create-project {
									width: 100%;
									font-size: $font-size * 1.5;
									margin: $one-unit * 2 0;
									border-radius: $border-radius;
									padding: $one-unit * 2;
									background-color: $green;

									i {
										margin-left: $one-unit;
									}
								}
							}

							&.deadlines {
								.deadline {
									display: flex;
									align-items: flex-start;
									flex-direction: column;
									position: relative;
									padding-right: $gutter;
									border-bottom: 1px solid $hairline-grey;
									margin-bottom: $one-unit * 2;

									.row {
										width: 100%;

										.deadline-key-date {
											flex: 1;
										}

										.deadline-task {
											flex: 1;
										}

										.deadline-connection {
											flex: 0;
										}
									}

									.form-group {
										flex: 1;
									}
								}

								button.close {
									right: 4px;
									position: absolute;
								}
							}
						}
					}

					&.template {
						.form-group {
							display: block;
						}
					}
				}

				footer {
					flex-direction: row;
					grid-area: wizard-footer;
					justify-content: space-between;

					.next,
					.back {
						width: $gutter * 4;

						button {
							cursor: pointer;
							width: 100%;
							color: $white;
							border: none;
							padding: $one-unit $one-unit * 2;
							border-radius: $border-radius;
						}
					}

					.next {
						button {
							background: $blue;
						}

						order: 2;
						font-weight: bold;
						text-align: right;
					}

					.back {
						button {
							color: $grey;
						}

						order: 1;
						opacity: 0.5;
					}
				}
			}
		}
	}
}
