#app.templates {
  #phase-card,
  #ledger-card {
    .list-group.tasks {
      .list-group-item.task {
        position: relative;
        &.first-template-task {
          background-color: $yellow;

          &:hover {
            background-color: $accent-yellow;
            border-color: $accent-yellow;
          }
        }
      }
    }
  }
}
