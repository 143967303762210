.application-layout {
  article#workflows-card {
    article#workflow-card {
      article.phase {
        position: relative;
        .milestone-label {
          display: none;
        }
        hr {
          border: 1px solid $hairline-grey;
          position: absolute;
          left: -1px;
          top: 0;
          height: 110%;
        }
        &.milestone {
          .milestone-label {
            position: absolute;
            top: 16px;
            left: -8px;
            display: block;
            color: $orange;
            font-size: $font-size * 1.2;
          }
          &.overdue {
            .milestone-label {
              color: $red;
            }
          }
          &.slipping {
            .milestone-label {
              color: $orange;
            }
          }
          &.open {
            .milestone-label {
              color: $yellow;
            }
          }
          &.empty {
            .milestone-label {
              color: $hairline-grey;
            }
          }
        }
        .card-block {
          border: none;
          margin-bottom: $one-unit * 2;
          padding-right: 0;
          padding-left: 0;
        }
        .phase-deadline-dates {
          display: flex;
          position: absolute;
          width: 100%;
          right: $one-unit * 2;
          top: 18px;
          text-align: right;
          color: $mutedBlack;
          font-weight: 200;
          flex-direction: row;
          justify-content: flex-end;
          .fixed,
          .projected {
            margin-left: 4px;
          }
        }
        .rollup {
          position: absolute;
          right: $one-unit * 2;
          top: 0;
          width: $one-unit * 2;
          height: $gutter;
          &::before {
            color: $accent-color2;
            content: "\f107 ";
            font: normal normal normal $font-size FontAwesome;
          }
        }
        &.closed {
          .rollup {
            &::before {
              content: "\f106 ";
            }
          }
          opacity: 0.6;
          h2 {
            border-bottom: 3px double $hairline-grey;
            padding-bottom: 8px;
          }
          .list-group-component {
            display: none;
          }
        }
      }
    }
  }
}

#app.templates {
  article#resourcing-card {
    table {
      tbody {
        th {
          font-weight: 400;
          text-align: right;
        }
        td {
          border-color: $hairline-grey;
        }
      }
      .phase {
        max-width: $gutter * 4;
        width: $gutter * 4;
        text-align: center;
        span.dots {
          width: $gutter;
          display: inline-block;
          svg {
            margin: 1px;
          }
        }
      }
    }
    .milestone-phase {
      background-color: lighten($yellow, 25%);
      border-color: lighten($yellow, 17%);
      * {
        color: $accent-yellow;
      }
    }
  }
}

article#phases-card,
article#ledgers-card {
  article.phase,
  article.ledger {
    table.table {
      border: none;
      tr {
        th,
        td {
          &.role {
            width: 50%;
          }
          &.button {
            width: 32px;
          }
          border: none;
          padding: $one-unit;
        }
      }
      tfoot {
        td {
          border-top: 1px solid $hairline-grey;
        }
      }
    }
    .description {
      font-style: italic;
      margin: $gutter/1.6 0;
      line-height: 1.6;
    }
    .badge {
      margin-left: $one-unit;
      background-color: $accent-yellow;
    }
    div.phase-part {
      margin-bottom: $one-unit * 2;
      border-top: 2px dotted $red;
      &:first-of-type {
        padding-top: $one-unit * 2;
        border-top: none;
      }
    }
  }
}
