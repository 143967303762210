.task-closed {
	.task-comments {
		.comments {
			button.close {
				color: $white;
			}

			.documents-upload {
				color: $green;

				label.btn {
					border-color: darken($green, 20);
					color: darken($green, 20);
				}
			}
		}
	}
}

#app {
	#action-menu-button {
		i {
			@include animate-state;
		}

		&.action-menu-open {
			i {
				@include animate-state;
				transform: rotate(-45deg);
			}
		}
	}

	#action-menu {
		&.action-menu-open {
			display: block;
		}

		@include depth;
		display: none;
		z-index: 1;
		border-radius: $border-radius;
		background-color: $white;
		position: absolute;
		bottom: $one-unit * 8;
		right: $one-unit;
		width: $one-unit * 60;
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline;

			&:last-child {
				label,
				button {
					border-radius: 0 0 $border-radius $border-radius;
				}
			}

			&:first-child {
				label,
				button {
					border-radius: $border-radius $border-radius 0 0;
				}
			}

			label {
				&:hover {
					@include animate-state;
					color: $white;
					background-color: $blue;
				}

				i {
					margin: 0;
				}

				@include reset;
				@include animate-state;
				color: $black;
				padding: $one-unit * 2;
				cursor: pointer;
				width: 100%;
				height: auto;
				border: none;
				background: none;
				text-align: left;
				font-family: $font-family-body;
				font-size: $font-size;
			}

			button {
				&:hover {
					color: $white;
					background-color: $blue;
				}

				@include animate-state;
				cursor: pointer;
				font-family: $font-family-body;
				font-size: $font-size;
				padding: $one-unit * 2;
				color: lighten($black, 20%);
				display: flex;
				width: 100%;
				height: auto;
				border: none;
				background: none;
				text-align: left;
				border-bottom: 1px solid $hairline-grey;
				align-items: center;

				span {
					flex: 1;
					margin: 0 3px;
				}
			}
		}
	}
}
