svg.property-plan {
  border: 1px solid $hairline-grey;
  margin: $one-unit * 2;
  border-radius: $border-radius;
  g.tenancy {
    &.active {
      text {
        opacity: 1;
        fill: $white;
      }
      path {
        fill: $orange;
        stroke: lighten($orange, 10);
      }
    }
    &:hover {
      text {
        @include animate-state;
        fill: $black;
        opacity: 1;
      }
      path {
        @include animate-state;
        fill: $bg-color;
        stroke: $black;
      }
    }
    circle,
    text,
    path {
      opacity: 1;
      transform: scale(1);
    }
    circle {
      stroke: $orange;
    }
    text {
      @include animate-state;
      fill: $accent-color1;
      opacity: 0.5;
    }
    path {
      @include animate-state;
      fill: $white;
      stroke: $accent-color1;
    }
  }
}

.card-block {
  svg.property-plan {
    margin: 0;
  }
}
