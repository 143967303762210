.markup {
  font-weight: 200;
  p,
  br {
    display: block;
    margin-bottom: $gutter /4 !important;
  }
  a {
    color: $blue !important;
    text-decoration: underline !important;
    &::after {
      font-family: FontAwesome;
      padding-left: 3px;
      content: '\f08e ';
    }
    &:hover {
      color: $white !important;
    }
  }
}
