section {
  #users-card.card {
    .list-group-item {
      > * {
        margin-right: $font-size;
      }
      .user-email {
        font-weight: 600;
        display: inline;
      }
      .user-role {
        font-style: italic;
        display: inline;
      }
      .fa-badge-check {
        color: $accent-blue;
      }
    }
  }
}
