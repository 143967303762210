.tasks.card-group {
  display: flex;
  flex-direction: row;
  margin: $one-unit;
  .task-card {
    @include task-status;
    border: 1px solid $hairline-grey;
    border-radius: $border-radius;
    margin: 0 $one-unit $one-unit 0;
    padding: floor($one-unit * 2);
    background: $white;
    box-shadow: none;
    flex: 1 0 $gutter * 5;
    max-width: $gutter * 5;
    min-height: $gutter * 4;
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    .card-title {
      margin: 0;
      height: $gutter;
      h6 {
        font-weight: 400;
        margin: 0;
        font-size: round($font-size * 0.8);
      }
      svg {
        position: absolute;
        top: $one-unit * 2;
        right: $one-unit * 2;
        width: $one-unit * 2;
        height: $one-unit * 2;
      }
    }
    .card-body {
      flex: 1;
      margin: 0;
      h4 {
        margin: 0 0 floor($one-unit);
        font-size: round($font-size * 0.9);
      }
      h3 {
        margin: 0;
        font-size: round($font-size);
      }
    }
  }
}

.card {
  .tasks.card-group {
    margin: 0;
  }
}