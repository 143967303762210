@import "documents/list";
@import "documents/show";
@import "documents/download";

#uploads {
	z-index: 9999;
	position: fixed;
	bottom: $one-unit;
	padding: $one-unit * 2;
	left: $one-unit;
	min-width: 320px;
	opacity: 0.95;
	background: $orange;
	color: $white;
	border-radius: $border-radius;
	@include depth;
	.upload {
		border-bottom: 1px solid mix($white, $orange, 30);
		margin-bottom: $one-unit;
		padding-bottom: $one-unit;
		padding-left: $gutter * 1.2;
		position: relative;
		.upload-status {
			.progress {
				margin: $one-unit 0;
			}
		}
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		button {
			position: absolute;
			bottom: 3px;
			right: 0;
			border: 1px solid $white;
			background: $orange;
			color: $white;
			border-radius: $border-radius;
		}
		i {
			position: absolute;
			left: 0;
			top: 2px;
		}
		h4 {
			margin: 0;
			font-weight: 500;
		}
		p {
			margin: 0;
		}
	}
}

.codex-editor {
	margin-top: $gutter;

	.merge-field {
		text-align: center;
		padding: $one-unit * 2;
		border-radius: $border-radius;
		color: $black;
		background-color: lighten($accent-yellow, 30%);
		border: 1px solid $accent-yellow;
	}
}

article.preview {
	.card-block {
		display: flex;
		justify-content: center;

		.document {
			width: $gutter * 20;
			border: 1px solid $hairline-grey;
			margin: $gutter;
			padding: $gutter;

			table {
				width: 100%;
				margin-bottom: $gutter;

				tbody {
					tr {
						th,
						td {
							border: 1px solid $hairline-grey;
						}

						th {
							width: 240px;
							padding: $one-unit;
						}

						td {
							padding: $one-unit;
						}
					}
				}
			}

			samp {
				background-color: $black;
				color: $white;
				padding: $one-unit * 2;
				border-radius: $border-radius;
				margin-bottom: $one-unit * 2;
				display: block;
				width: 100%;
			}

			blockquote {
				background: #f9f9f9;
				border-left: $one-unit solid #ccc;
				margin: $one-unit * 2 $one-unit * 2 $one-unit * 2 0;
				padding: $one-unit * 2;
				quotes: "\201C""\201D""\2018""\2019";

				&:before {
					color: #ccc;
					content: open-quote;
					font-size: 4em;
					line-height: 0.1em;
					margin-right: 0.25em;
					vertical-align: -0.4em;
				}

				cite {
					display: block;
					margin-left: $font-size;
					font-size: 0.9 * $font-size;
				}
			}
		}
	}
}
