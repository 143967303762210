.workspace#templates {
  .list-group-component-templates {
    .list-group-item.template {
      .updated_at.list-group-item-datapoint {
        min-width: 150px;
        text-align: center;
        .formatted-date-time {
          display: none;
        }
      }
    }
  }
}
