body.analytics {
  table.table {
    tbody {
      tr {
        td.task {
          position: relative;
          span {
            display: block;
            &.formatted-date {
              text-align: left;
              position: absolute;
              top: $one-unit * 2;
              left: $one-unit * 2;
            }
          }
          &.closed {
            color: $white;
            background-color: $green;
            border-color: $green;
          }
          &.overdue {
            color: $white;
            background-color: $red;
            border-color: $red;
          }
          &.very-overdue {
            color: $white;
            background-color: darken($red, 1);
            border-color: darken($red, 1);
          }
        }
      }
    }
  }
}
