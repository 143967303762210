svg.svg-inline--fa rect,
svg.svg-inline--fa circle,
svg.svg-inline--fa path,
svg.svg-inline--fa polygon {
  opacity: 1 !important;
  transform: scale(1) !important;
}

svg.svg-inline--fa.fa-fw {
  width: $gutter;
}
