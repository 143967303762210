nav.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $one-unit * 2;
  p {
    color: $accent-color2;
    font-size: $font-size * 0.7;
    text-align: center;
    display: block;
    margin: 0;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 $one-unit/4;
    padding: 0;
    border-radius: $border-radius;
    border: 2px solid $hairline-grey;
    li.page-item {
      border: none;
      &.active,
      &:hover {
        a.page-link {
          color: $accent-color1;
          background-color: $hairline-grey;
        }
      }
      a.page-link {
        border: none;
        border-radius: 0;
        color: $grey;
        font-size: $font-size * 0.9;
      }
    }
  }
  &.grouped {
    ul {
      flex-direction: column;
    }
  }
}
