.home {
	.row {
		.col {
			border: none !important;

			&:first-of-type {
				padding-right: 0 !important;
			}
			.card {
				margin-bottom: $one-unit * 2;

				.list-group-item {
					.stamp {
						margin-right: $one-unit;
					}
				}

				.datapoint {
					margin-bottom: $one-unit * 2;
				}

				&.likes {
					.list-group-item {
						padding: $one-unit $one-unit * 2;

						.list-group-item-heading {
							h3 {
								font-size: $font-size;
							}

							h6 {
								font-size: $font-size * 0.8;
								color: $mutedBlack;
							}
						}

						.stamp {
							width: $font-size;

							i {
								font-size: $font-size;
							}
						}
					}
				}
			}
		}
	}

	#headline-card {
		text-align: left;
		padding: $one-unit * 2;
		margin: $one-unit * 2 $one-unit * 2 0;

		.card-block {
			padding: 0;

			img {
				height: $gutter * 2.5;
				width: $gutter * 2.5;
				margin-right: $gutter * 0.5;
			}

			.datapoint {
				margin-right: $one-unit * 2;
				margin-left: 0;

				&:first-of-type {
					margin-left: 0;
				}

				&:last-of-type {
					margin-right: 0;
				}

				&.info {
					color: $white;
					background: $dark-blue;
					margin-right: $one-unit * 2;

					.datapoint-name {
						margin-left: $one-unit;
					}
				}

				.datapoint-name {
					text-align: left;
				}
			}
		}
	}

	#help-card {
		.list-group-item {
			.stamp {
				width: $gutter;
				margin-right: $one-unit;

				i {
					font-size: $font-size * 1.5;
				}
			}

			h3 {
				font-size: $font-size;
			}
		}
	}

	#project-status-card {
		.datapoint {
			svg {
				margin-right: $one-unit;
			}
			&.project-ahead {
				svg {
					color: $green;
				}
			}
			&.project-behind {
				svg {
					color: $red;
				}
			}
			&.project-defect {
				svg {
					color: $blue;
				}
			}
			&.project-closed {
				svg {
					color: $dark-blue;
				}
			}
		}
	}

	#home-main {
		#home-today-card {
			.card-block {
				.row {
					display: flex;
					flex-direction: row;
					.tasks {
						flex-basis: 30%;
					}

					.list-group {
						.list-group-item {
							.list-group-item-content {
								width: 100%;
							}
							.list-group-item-heading h3 {
								font-size: $font-size;

								order: 1;
								flex-basis: auto !important;
							}
							.list-group-item-statistics,
							.list-group-item-datapoints,
							.list-group-item-parent,
							.badge,
							.disclosure {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
