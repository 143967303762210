// header.report-header {
// 	margin: $one-unit * 4;
// 	.page-title,
// 	.feature-flag {
// 		display: inline-block;
// 	}
// }
#project-phase-analytics-report-card {
	.report-header {
		border-bottom: 1px solid #ccc;
	}

	.report-body {
		// margin: $one-unit * 4;
		// padding: $one-unit;
		background-color: $hairline-grey;
		.report-item {
			display: flex;
			flex-direction: column;
			margin-bottom: $one-unit * 2;
			padding: $one-unit * 2;

			header {
				display: flex;
				flex-direction: row;

				.status {
					display: none;
				}
				& > * {
					margin-right: $one-unit * 2;
				}
			}
			.workflow-visual {
				display: flex;
				.workflow-status {
					display: flex;
					.phase {
						flex-direction: row;
						margin: 0 $one-unit * 6 0 0;
						text-align: center;
					}
				}
			}
		}
	}
}

.disabled-cursor {
	pointer-events: none;
}
